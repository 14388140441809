import axios from "axios";

const bun_server = "https://geoserver.mapid.io";
// const bun_server = "http://localhost:4004";

/*PROTOTYPE*/

export const get_sheets_by_cell = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    const { feature_key, field_key, geo_layer_id } = body;

    // dispatch(setLoadingProcess("delete_project_to_recovery"));

    const res = await axios.get(
      bun_server + `/sheets?feature_key=${feature_key}&field_key=${field_key}&geo_layer_id=${geo_layer_id}`,
      config
    );

    // dispatch(clearLoading());
    return res?.data;
  } catch (e) {
    return;
    // dispatch(clearLoading());
  }
};


export const create_sheet = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    const res = await axios.post(
      bun_server + `/sheets`,
      body,
      config
    );

    return res?.data;
  } catch (e) {
    console.log("error:", e)
    return;
  }
};

export const delete_sheet_by_id = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    const {_id} = body; 

    if(_id){ 
      const res = await axios.delete(
        bun_server + `/sheets/${_id}`,
        config
      );
      return res;
    }
    throw new Error("No id");
  
  } catch (e) {
    console.log("error:", e)
    return;
  }
};

// export const setLoadingProcess = (itemLoading) => {
//   return {
//     type: "SET_LOADING_PROCESS_LAYER",
//     payload: itemLoading,
//   };
// };

// export const clearLoading = () => {
//   return {
//     type: "CLEAR_LOADING_LAYER",
//   };
// };