import { Menu, MenuItem } from '@mui/material';
import React, { Component } from 'react'
import { delete_sheet_by_id } from '../../App/actions/sheetAction';
import { connect } from 'react-redux';

class SheetButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // modal_right_click: false,
      anchorEl: null,
    }
  }

  toggle_right_click = (event) => {
    event.preventDefault();
    this.setState({ anchorEl: event.currentTarget });
  }

  toggle_close = () => {
    this.setState({ anchorEl: null });
  }

  handleDelete = async () => {
    const { sheetname, setSheetnames, sheetnames, selectedSheet, setSelectedSheet, worksheet, setWorksheet } = this.props
    const res = await this.props.delete_sheet_by_id({ _id: sheetname?._id });
    if(res?.status === 200){
      const newSheetnames = sheetnames.filter(item=> item?._id !== sheetname?._id);
      setSheetnames(newSheetnames);
      let filteredWorksheet = worksheet.filter(item => item?.metadata?.id_ori !== selectedSheet);
      if(filteredWorksheet.length>0){
        setWorksheet(filteredWorksheet)
      }else{
        setWorksheet(null)
      }
      
      if(sheetname?.id_ori === selectedSheet ){
        const newSelectedSheet = newSheetnames?.[0]?.id_ori || ""
        setSelectedSheet(newSelectedSheet);
      }
    }
  }

  render() {
    const { handleSheetChange, sheetname, selectedSheet } = this.props
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div
        className='noselect'
        onContextMenu={this.toggle_right_click}
        onClick={(e) => {
          handleSheetChange(sheetname?.id_ori);
        }}

      >

        <label className={`pointer no_wrap padding_2 hover_bold ${selectedSheet === sheetname?.id_ori ? "underline" : ""}`}>
          {sheetname?.name}
        </label>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.toggle_close}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={this.handleDelete}>Delete</MenuItem>
        </Menu>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  delete_sheet_by_id
})(SheetButton);