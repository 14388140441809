/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import bbox from "@turf/bbox";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/

class LAYER_INSIGHT extends Component {
  state = {};

  componentDidUpdate(prevProps) {
    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;
    if (
      this.props.insight_reducer.insight_update !==
        prevProps.insight_reducer.insight_update ||
      basemap_used_after !== basemap_used_before
    ) {
      this.on_render();
    }
    if (
      this.props.insight_reducer.insight_fly_update !==
      prevProps.insight_reducer.insight_fly_update
    ) {
      this.on_fly_content();
    }
  }

  on_render = () => {
    const { features_poi, features_demography, features_grid } =
      this.props.insight_reducer;
    const { map_object } = this.props.layer;
    if (!map_object) return;

    const geojson_demografi = {
      type: "FeatureCollection",
      features: features_demography,
    };
    const geojson_poi = {
      type: "FeatureCollection",
      features: features_poi,
    };
    const geojson_grid = {
      type: "FeatureCollection",
      features: features_grid,
    };

    const layer_demografi_id = "DEMOGRAFI_INSIGHT";
    const layer_poi_id = "POI_INSIGHT";
    const layer_grid_line_id = "GRID_INSIGHT_LINE";
    const layer_grid_polygon_id = "GRID_INSIGHT_POLYGON"; // New ID for polygon layer

    // Add or update source and layer for demographic data
    if (!map_object.getSource(layer_demografi_id)) {
      map_object.addSource(layer_demografi_id, {
        type: "geojson",
        data: geojson_demografi,
      });
    } else {
      map_object.getSource(layer_demografi_id).setData(geojson_demografi);
    }

    if (!map_object.getLayer(layer_demografi_id)) {
      map_object.addLayer({
        id: layer_demografi_id,
        type: "line",
        source: layer_demografi_id,
        paint: {
          "line-color": "#db1f2e",
          "line-width": 1,
        },
        layout: {
          visibility: "visible",
        },
      });
    }

    // Add or update source and layer for POI data
    if (!map_object.getSource(layer_poi_id)) {
      map_object.addSource(layer_poi_id, {
        type: "geojson",
        data: geojson_poi,
      });
    } else {
      map_object.getSource(layer_poi_id).setData(geojson_poi);
    }

    if (!map_object.getLayer(layer_poi_id)) {
      map_object.addLayer({
        id: layer_poi_id,
        type: "circle",
        source: layer_poi_id,
        paint: {
          "circle-color": "#21a5f6",
          "circle-radius": 2,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
        layout: {
          visibility: "visible",
        },
      });
    }

    // Add or update source and layer for grid polygons
    if (!map_object.getSource(layer_grid_polygon_id)) {
      map_object.addSource(layer_grid_polygon_id, {
        type: "geojson",
        data: geojson_grid,
      });
    } else {
      map_object.getSource(layer_grid_polygon_id).setData(geojson_grid);
    }

    if (!map_object.getLayer(layer_grid_polygon_id)) {
      map_object.addLayer({
        id: layer_grid_polygon_id,
        type: "fill",
        source: layer_grid_polygon_id,
        paint: {
          "fill-opacity": 0.5,
          "fill-color": [
            "match",
            ["get", "text_score"],
            "Sangat Sesuai",
            "rgb(0,107,61)",
            "Sesuai",
            "rgb(128,181,30)",
            "Cukup Sesuai",
            "rgb(255,255,0)",
            "Tidak Sesuai",
            "rgb(246,137,11)",
            "Sangat Tidak Sesuai",
            "rgb(237,19,22)",
            "#fff",
          ],
        },
        layout: {
          visibility: "visible",
        },
      });
    }

    if (!map_object.getSource(layer_grid_line_id)) {
      map_object.addSource(layer_grid_line_id, {
        type: "geojson",
        data: geojson_grid,
      });
    } else {
      map_object.getSource(layer_grid_line_id).setData(geojson_grid);
    }

    if (!map_object.getLayer(layer_grid_line_id)) {
      map_object.addLayer({
        id: layer_grid_line_id,
        type: "line",
        source: layer_grid_line_id,
        paint: {
          "line-color": "#000",
          "line-width": 1.5,
        },
        layout: {
          visibility: "visible",
        },
      });
    }
  };

  on_fly_content = () => {
    const { map_object, is_pause_zoom_map } = this.props.layer;
    if (!is_pause_zoom_map) {
      const { features_demography } = this.props.insight_reducer;
      if (!map_object || features_demography.length === 0) return;
      const geojson_demografi = {
        type: "FeatureCollection",
        features: features_demography,
      };
      const [minLng, minLat, maxLng, maxLat] = bbox(geojson_demografi);
      let padding = { top: 100, bottom: 100, left: 500, right: 500 };
      if (window.innerWidth < 1172) {
        padding = {
          top: 50,
          bottom: 400,
          left: 10,
          right: 10,
        };
      }
      map_object.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding,
          maxZoom: this.props.layer.max_zoom,
        }
      );
    }
  };

  render() {
    return <main></main>;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  properties: state.properties,
  insight_reducer: state.insight_reducer,
});

export default connect(mapStateToProps, {})(LAYER_INSIGHT);
