const formula = require('formulajs');

const numberToColumn = (num) => {
  let col = '';
  while (num > 0) {
    const remainder = (num - 1) % 26;
    col = String.fromCharCode('A'.charCodeAt(0) + remainder) + col;
    num = Math.floor((num - 1) / 26);
  }
  return col;
};

// Fungsi untuk mengonversi huruf kolom (seperti A, AA) ke indeks angka
const columnToNumber = (col) => {
  let number = 0;
  for (let i = 0; i < col.length; i++) {
    number = number * 26 + (col.charCodeAt(i) - 'A'.charCodeAt(0) + 1);
  }
  return number;
};

function getSheetData({ sheetName, rawData }) {
  if (!sheetName) return;
  const sheet = rawData.find((item) => item.metadata.id_ori === sheetName);
  if (!sheet) return;
  return sheet.value;
}

function getValue(sheet, cellRef) {
  if (sheet) {
    const cell = sheet.find(row => row.p === cellRef);
    return cell?.v || 0
  } else {
    return "#REF!"
  }
}

function parseReference(reference) {
  // const match = reference.match(/^'?([\w\s\-.]+)'?!([A-Z]+\d+)$/);
  const match = reference.match(/^'?([\w\s\-\.,]+)'?!([A-Z]+\d+(:[A-Z]+\d+)?)$/);
  if (match) {
    return { sheetName: match[1], cellRef: match[2] };
  }
  return { sheetName: null, cellRef: reference };
}

const getAllSelectedValue = ({ rangeCode, sheetName, rawData }) => {
  // console.log(rangeCode)
  const [startCell, endCell] = rangeCode.split(':').map(cell => cell.trim());
  const startRow = parseFloat(startCell.match(/\d+/)[0]);
  const endRow = parseFloat(endCell.match(/\d+/)[0]);
  const startCol = startCell.match(/^[A-Z]+/)[0];
  const endCol = endCell.match(/^[A-Z]+/)[0];

  let sheetData = getSheetData({ sheetName, rawData });
  const values = [];

  const startColNum = columnToNumber(startCol);
  const endColNum = columnToNumber(endCol);

  for (let row = startRow; row <= endRow; row++) {
    for (let colNum = startColNum; colNum <= endColNum; colNum++) {
      const colLetter = numberToColumn(colNum);
      values.push(getValue(sheetData, `${colLetter}${row}`));
    }
  }

  return values;
}

const calculateExcelFormula = ({ sheetName, cell, rawData }) => {

  if (!cell || !cell.f) {
    return cell ? cell.v : undefined;
  }

  const formulaStr = cell.f?.replace(/\$/g, "");

  function formatText(text) {
    return text.replace(/"&([^&]*)&"/g, "$1");
  }





  

  // Mendapatkan nilai referensi antar-cell dalam formula
  let formulaWithValues = formulaStr.replace(/'[^']+'![A-Z]+\d+|[A-Z]+\d+/g, (match) => {
    const { sheetName: refSheet, cellRef } = parseReference(match);
    const sheetToUse = refSheet || sheetName;
    const sheetData = getSheetData({ sheetName: sheetToUse, rawData });
    return getValue(sheetData, cellRef);
  });

  function removeFirstAndLastChar(text) {
    return text.slice(1, -1);
  }

  function evaluateTextFormula(formulaStr) {
    let text = formatText(formulaStr)
    text = text.replace(/\((".*?"|\(.*?\))(\s*&[A-Z]+\d+&\s*)*.*?\)/g, (match) => {
      // text.replace(/"[^"]*"|([A-Z]+\d+)/g, (match) => {
      let newText = match
      if ((newText.startsWith('"') || newText.startsWith('(')) && (newText.endsWith('"') || newText.endsWith(')'))) {
        while ((newText.startsWith('"') || newText.startsWith('(')) && (newText.endsWith('"') || newText.endsWith(')'))) {
          newText = removeFirstAndLastChar(newText);
        }

        return newText;
      } else {
        // const { sheetName: refSheet, cellRef } = parseReference(match);
        // const sheetToUse = refSheet || sheetName;
        // const sheetData = getSheetData(sheetToUse);

        return text //getValue(sheetData, cellRef);
      }
    });

    return text;
  }

  // Fungsi IRR
  function calculateIRR(cashFlows, guess = 0.1) {
    const maxIterations = 1000;
    const precision = 1e-6;
    let rate = guess;

    for (let i = 0; i < maxIterations; i++) {
      let npv = cashFlows.reduce((sum, cf, t) => sum + cf / Math.pow(1 + rate, t), 0);
      let npvDerivative = cashFlows.reduce((sum, cf, t) => sum - (t * cf) / Math.pow(1 + rate, t + 1), 0);

      let newRate = rate - npv / npvDerivative;

      if (Math.abs(newRate - rate) < precision) {
        return newRate;
      }
      rate = newRate;
    }
    throw new Error("IRR calculation did not converge.");
  }

  // Fungsi RANK
  function calculateRank(value, range, order = "DESC") {
    const sortedRange = [...range].sort((a, b) => (order === "ASC" ? a - b : b - a));
    return sortedRange.indexOf(value) + 1;
  }

  // function getAllSelectedValue({ rangeCode, sheetName }) {

  //   const [startCell, endCell] = rangeCode.split(':').map(cell => cell.trim());
  //   const startRow = parseFloat(startCell.match(/\d+/)[0]);
  //   const endRow = parseFloat(endCell.match(/\d+/)[0]);
  //   const startCol = startCell.match(/^[A-Z]+[0-9]/)[0];
  //   const endCol = endCell.match(/^[A-Z]+[0-9]+$/)[0];

  //   const sheetData = getSheetData(sheetName);
  //   const values = [];

  //   // Convert column letters to numbers for easy iteration
  //   const startColNum = startCol.charCodeAt(0);
  //   const endColNum = endCol.charCodeAt(0);

  //   for (let row = startRow; row <= endRow; row++) {
  //     for (let colNum = startColNum; colNum <= endColNum; colNum++) {
  //       const colLetter = String.fromCharCode(colNum);
  //       values.push(getValue(sheetData, `${colLetter}${row}`));
  //     }
  //   }

  //   return values
  // }




  function replaceBoolean(formula) {
    if (formula.includes("TRUE")) {
      formula = formula.replaceAll("TRUE", true)
    }

    if (formula.includes("FALSE")) {
      formula = formula.replaceAll("FALSE", false)
    }

    return formula
  }


  try {
    if (formulaWithValues.startsWith("SUM(")) {
      // SUM function
      const params = formulaStr.match(/SUM\(([^)]+)\)/)?.[1]?.split(",");
      let result = [];
      const data = getSheetData({ sheetName, rawData })
      for (let item of params) {
        if (item?.includes(":")) {
          result = [...result, ...getAllSelectedValue({ rangeCode: item, sheetName, rawData })]
        } else {
          const value = getValue(data, item);
          result.push(value)
        }
      }
      return formula.SUM(result);
    } else if (formulaWithValues.startsWith("ROUNDDOWN(")) {
      // Parsing untuk fungsi ROUNDDOWN
      // return formulaWithValues
      const roundDownMatch = formulaWithValues.match(/ROUNDDOWN\(([^,]+),\s*([^)]+)\)/);
      if (roundDownMatch) {
        const number = parseFloat(roundDownMatch[1]);
        const digits = Number(roundDownMatch[2]) < 5 ? Number(roundDownMatch[2]) : 3;
        return Math.floor(number * Math.pow(10, digits)) / Math.pow(10, digits);
      }

    } else if (formulaWithValues.startsWith("AVERAGE(")) {
      // AVERAGE function
      // const range = formulaStr.match(/AVERAGE\(([^)]+)\)/)[1].split(':');
      // const values = range.map(ref => getValue(getSheetData(sheetName), ref.trim()));


      const params = formulaStr.match(/AVERAGE\(([^)]+)\)/)?.[1]?.split(",");
      let result = [];
      const data = getSheetData({ sheetName, rawData })
      for (let item of params) {
        if (item?.includes(":")) {
          result = [...result, ...getAllSelectedValue({ rangeCode: item, sheetName, rawData })]
        } else {
          const value = getValue(data, item);
          result.push(value)
        }
      }

      return formula.AVERAGE(result);
    } else if (formulaWithValues.startsWith("RANK(")) {
      // Parsing untuk fungsi RANK
      const rankMatch = formulaStr.match(/RANK\(([^,]+),\s*([^,]+)(?:,\s*([^,]+))?\)/);
      if (rankMatch) {
        const value = parseFloat(getValue(getSheetData({ sheetName, rawData }), rankMatch[1]));
        const rangeCode = rankMatch[2];
        const range = getAllSelectedValue({ rangeCode, sheetName, rawData })
        const order = rankMatch[3]?.toUpperCase() === "ASC" ? "ASC" : "DESC"; // Default DESC
        return calculateRank(value, range, order);
      }
    } else if (formulaWithValues.startsWith("IRR(")) {
      const params = formulaStr.match(/IRR\(([^)]+)\)/)
      const cashFlowRangeCode = params?.[1];
      const guess = params?.[2];
      const cashFlows = getAllSelectedValue({ rangeCode: cashFlowRangeCode, sheetName, rawData })
      return calculateIRR(cashFlows, guess);
    } else if (formulaWithValues.startsWith("COUNT(")) {
      // COUNT function
      // const rangeCode = formulaStr.match(/COUNT\(([^)]+)\)/)[1];
      // const values = getAllSelectedValue({ rangeCode: rangeCode, sheetName })
      const params = formulaStr.match(/COUNT\(([^)]+)\)/)?.[1]?.split(",");
      let result = [];
      const data = getSheetData({ sheetName, rawData })
      for (let item of params) {
        if (item?.includes(":")) {
          result = [...result, ...getAllSelectedValue({ rangeCode: item, sheetName, rawData })]
        } else {
          const value = getValue(data, item);
          result.push(value)
        }
      }

      return formula.COUNT(result);
    }
    // else if (formulaWithValues.startsWith("VLOOKUP(")) {
    //   // VLOOKUP function
    //   const [searchValue, tableRange, colIndex, isApproximate] = formulaWithValues.match(/VLOOKUP\(([^)]+)\)/)[1].split(',').map(item => item.trim());
    //   const tableData = getSheetData(sheetName);
    //   return formula.VLOOKUP(searchValue, tableData, parseInt(colIndex), Boolean(isApproximate));
    // } 
    // else if (formulaWithValues.startsWith("HLOOKUP(")) {
    //   // HLOOKUP function
    //   const [searchValue, tableRange, rowIndex, isApproximate] = formulaWithValues.match(/HLOOKUP\(([^)]+)\)/)[1].split(',').map(item => item.trim());
    //   const tableData = getSheetData(sheetName);
    //   return formula.HLOOKUP(searchValue, tableData, parseInt(rowIndex), Boolean(isApproximate));
    // } 
    else if (formulaWithValues.startsWith("LEFT(")) {
      // LEFT function
      let [text, numChars] = formulaWithValues.match(/LEFT\(([^)]+)\)/)[1].split(',').map(item => item.trim());
      while ((text.startsWith('"') || text.startsWith('(')) && (text.endsWith('"') || text.endsWith(')'))) {
        text = removeFirstAndLastChar(text);
      }
      return text.slice(0, parseInt(numChars));
    } else if (formulaWithValues.startsWith("MID(")) {
      // MID function
      let [text, startPos, numChars] = formulaWithValues.match(/MID\(([^)]+)\)/)[1].split(',').map(item => item.trim());
      while ((text.startsWith('"') || text.startsWith('(')) && (text.endsWith('"') || text.endsWith(')'))) {
        text = removeFirstAndLastChar(text);
      }
      return text.substr(parseInt(startPos) - 1, parseInt(numChars));
    } else if (formulaWithValues.startsWith("RIGHT(")) {
      // RIGHT function
      let [text, numChars] = formulaWithValues.match(/RIGHT\(([^)]+)\)/)[1].split(',').map(item => item.trim());
      while ((text.startsWith('"') || text.startsWith('(')) && (text.endsWith('"') || text.endsWith(')'))) {
        text = removeFirstAndLastChar(text);
      }
      return text.slice(-parseInt(numChars));
    }
    // else if (formulaWithValues.startsWith("SUMIF(")) {
    //   // SUMIF function
    //   const [range, criteria] = formulaWithValues.match(/SUMIF\(([^)]+)\)/)[1].split(',').map(item => item.trim());
    //   const values = range.map(ref => getValue(getSheetData(sheetName), ref));
    //   return formula.SUMIF(values, criteria);
    // } 
    else if (formulaWithValues.startsWith("MIN(")) {
      // MIN function
      const rangeCode = formulaStr.match(/MIN\(([^)]+)\)/)[1];
      const values = getAllSelectedValue({ rangeCode, sheetName, rawData })
      return formula.MIN(values);
    } else if (formulaWithValues.startsWith("MAX(")) {
      // MAX function
      const rangeCode = formulaStr.match(/MAX\(([^)]+)\)/)[1];
      const values = getAllSelectedValue({ rangeCode, sheetName, rawData })
      return formula.MAX(values);
    }
    // else if (formulaWithValues.startsWith("LEN(")) {
    //   // LEN function
    //   const text = formulaWithValues.match(/LEN\(([^)]+)\)/)[1].trim();
    //   return text.length;
    // } 
    // else if (formulaWithValues.startsWith("COUNTIF(")) {
    //   // COUNTIF function
    //   const [range, criteria] = formulaWithValues.match(/COUNTIF\(([^)]+)\)/)[1].split(',').map(item => item.trim());
    //   const values = range.map(ref => getValue(getSheetData(sheetName), ref));
    //   return formula.COUNTIF(values, criteria);
    // } 
    else if (formulaWithValues.startsWith("ROUND(")) {
      // ROUND function
      const [number, digits] = formulaWithValues.match(/ROUND\(([^)]+)\)/)[1].split(',').map(item => item.trim());
      return Math.round(parseFloat(number) * Math.pow(10, digits)) / Math.pow(10, digits);
    } else if (formulaWithValues.startsWith("AND(")) {
      formulaWithValues = replaceBoolean(formulaWithValues);
      // AND function
      const conditions = formulaWithValues.match(/AND\(([^)]+)\)/)[1].split(',').map(cond => eval(cond.trim()));
      const value = conditions.every(Boolean);
      return value
    } else if (formulaWithValues.startsWith("OR(")) {
      // OR function

      formulaWithValues = replaceBoolean(formulaWithValues);
      const conditions = formulaWithValues.match(/OR\(([^)]+)\)/)[1].split(',').map(cond => eval(cond.trim()));
      const value = conditions.some(Boolean);
      return value;
    } else if (formulaWithValues.startsWith("IF(")) {
      // IF function
      formulaWithValues = replaceBoolean(formulaWithValues);
      const ifMatch = formulaWithValues.match(/IF\(([^,]+),\s*([^,]+),\s*([^)]+)\)/);
      if (ifMatch) {
        const condition = eval(ifMatch[1].trim());
        const trueValue = ifMatch[2].trim();
        const falseValue = ifMatch[3].trim();
        return condition ? eval(trueValue) : eval(falseValue);
      }
    }
    // Additional functions continue here
    else if (/^[0-9+\-*/()^.\s]+$/.test(formulaWithValues)) {

      if (formulaWithValues.includes("/0")) {
        return "#DIV/0!"
      }

      // Evaluate basic arithmetic expressions with +, -, *, /
      if (/[+\-*/^]/.test(formulaWithValues)) {
        if (!formulaWithValues.includes("+-") || !formulaWithValues.includes("+(-")) {
          if (formulaWithValues.match(/(\+|-|\*|\/)(\s*[+*/-])+/g)) {
            return eval(formulaWithValues.replace(/(\+|-|\*|\/)(\s*[+*/-])+/g, "$10"));
          }
        }

        if (formulaWithValues.at(-1).match(/(\+|-|\*|\/)/)) {
          formulaWithValues = formulaWithValues.slice(0, -1);
        }

        if (formulaWithValues.includes("^")) {
          formulaWithValues = formulaWithValues.replaceAll("^", "**")
        }
        const result = eval(formulaWithValues)
        return result;
        // Evaluate addition or subtraction only, empty values become 0
      } else {
        // For multiplication or division, return "Error" if there are empty values
        return formulaWithValues;
      }
    } else {
      // console.log(cell.p,formulaWithValues)
      return evaluateTextFormula(formulaWithValues);
    }
  } catch (error) {
    console.error("Error evaluating formula:", formulaStr, error);
    return null;
  }
};

module.exports = { calculateExcelFormula, numberToColumn, columnToNumber, getAllSelectedValue, parseReference };
