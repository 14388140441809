/*LIBRARY MODULE*/
import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import { connect } from "react-redux";
import maplibregl from "maplibre-gl";
import circle from "@turf/circle";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

const create_marker_element = ({
  feature,
  idx,
  uuid_active,
  initial,
  map_object,
}) => {
  const uuid = `marker_toolbox_${feature.properties.uuid}`;
  let marker_element = document.getElementById(uuid);
  if (!marker_element) {
    marker_element = document.createElement("div");
  }
  marker_element.id = uuid;
  marker_element.style.position = "absolute";
  marker_element.style.transform = "translate(-50%, -50%)";
  const marker_jsx = (
    <div
      id={uuid}
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p
        className={`badge_small ${
          feature.properties.uuid === uuid_active
            ? "background_green"
            : "background_blue"
        }`}
      >
        {`${initial}-${idx + 1}`}
      </p>
    </div>
  );
  const marker_html = ReactDOMServer.renderToString(marker_jsx);
  marker_element.innerHTML = marker_html;

  const coordinates = feature.geometry.coordinates;
  const lngLat = new maplibregl.LngLat(coordinates[0], coordinates[1]);
  const point = map_object.project(lngLat);
  marker_element.style.left = `${point.x}px`;
  marker_element.style.top = `${point.y}px`;
  map_object.getContainer().appendChild(marker_element); //append the custom marker to the map container
  map_object.on("move", () => {
    const newPoint = map_object.project(lngLat);
    marker_element.style.left = `${newPoint.x}px`;
    marker_element.style.top = `${newPoint.y}px`;
  });
};

const create_marker_isokron = ({ feature, map_object }) => {
  const uuid = feature.properties.uuid;
  let marker_element = document.getElementById(uuid);
  if (!marker_element) {
    marker_element = document.createElement("div");
  }
  marker_element.id = uuid;
  marker_element.style.position = "absolute";
  marker_element.style.transform = "translate(-50%, -100%)";
  const marker_jsx = (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "black",
          color: "white",
          padding: "5px 10px",
          borderRadius: "25px",
          textAlign: "center",
        }}
      >
        {feature.properties.routing_profile},
        {feature.properties.contour_type === "minutes"
          ? feature.properties.contours_minutes
          : feature.properties.contours_meters}{" "}
        {feature.properties.contour_type === "minutes" ? "min" : "M"}
      </div>
      <div
        style={{ width: "2px", height: "50px", backgroundColor: "black" }}
      ></div>
    </div>
  );
  const marker_html = ReactDOMServer.renderToString(marker_jsx);
  marker_element.innerHTML = marker_html;
  const coordinates = feature.properties.most_west_coordinate;
  const lngLat = new maplibregl.LngLat(coordinates[0], coordinates[1]);
  const point = map_object.project(lngLat);
  marker_element.style.left = `${point.x}px`;
  marker_element.style.top = `${point.y}px`;
  map_object.getContainer().appendChild(marker_element);
  map_object.on("move", () => {
    const newPoint = map_object.project(lngLat);
    marker_element.style.left = `${newPoint.x}px`;
    marker_element.style.top = `${newPoint.y}px`;
  });
};

class LAYER_TOOLBOX extends Component {
  state = {};

  componentDidUpdate(prevProps) {
    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;
    if (
      this.props.toolbox.status_toolbox !== prevProps.toolbox.status_toolbox ||
      basemap_used_after !== basemap_used_before
    ) {
      const { map_object } = this.props.layer;
      if (map_object !== null) {
        if (!map_object.isStyleLoaded()) {
          map_object.once("styledata", () => {
            this.on_render();
          });
        } else {
          this.on_render();
        }
      }
    }
  }

  on_render = () => {
    const { map_object } = this.props.layer;
    if (map_object !== null) {
      this.on_render_distance();
      this.on_render_elevation();
      this.on_render_area();
      this.on_render_radius();
      this.on_render_isochrone();
    }
  };

  on_render_distance = () => {
    const { map_object } = this.props.layer;
    const {
      features_distance,
      features_distance_label,
      features_distance_center,
      uuid_distance,
    } = this.props.toolbox;
    const geojson_distance = {
      type: "FeatureCollection",
      features: features_distance,
    };
    const distance_id = "geojson_distance";
    if (!map_object.getSource(distance_id)) {
      map_object.addSource(distance_id, {
        type: "geojson",
        data: geojson_distance,
        cluster: false,
      });
    } else {
      map_object.getSource(distance_id).setData(geojson_distance);
    }
    if (!map_object.getLayer(distance_id)) {
      map_object.addLayer({
        id: distance_id,
        source: distance_id,
        type: "line",
        paint: {
          "line-color": "#104585",
          "line-width": 2,
          "line-opacity": 1,
        },
        layout: {
          visibility: "visible",
        },
      });
    }
    const geojson_distance_label = {
      type: "FeatureCollection",
      features: features_distance_label,
    };
    const distance_label_id = "geojson_distance_label";
    if (!map_object.getSource(distance_label_id)) {
      map_object.addSource(distance_label_id, {
        type: "geojson",
        data: geojson_distance_label,
        cluster: false,
      });
    } else {
      map_object.getSource(distance_label_id).setData(geojson_distance_label);
    }
    if (!map_object.getLayer("geojson_distance_label_background")) {
      map_object.addLayer({
        id: "geojson_distance_label_background",
        source: distance_label_id,
        type: "circle",
        paint: {
          "circle-color": "#ffffff",
          "circle-radius": 7,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#000000",
        },
        layout: {
          visibility: "visible",
        },
      });
    }
    if (!map_object.getLayer("geojson_distance_label")) {
      map_object.addLayer({
        id: "geojson_distance_label",
        source: distance_label_id,
        type: "symbol",
        layout: {
          "text-field": ["get", "name"],
          "text-size": 10,
          "icon-anchor": "center",
          visibility: "visible",
        },
      });
    }
    features_distance_center.forEach((feature, idx) => {
      create_marker_element({
        feature,
        idx,
        uuid_active: uuid_distance,
        initial: "D",
        map_object,
      });
    });
  };

  on_render_elevation = () => {
    const { map_object } = this.props.layer;
    const {
      features_elevation,
      features_elevation_point,
      features_elevation_center,
      uuid_elevation,
    } = this.props.toolbox;
    const geojson_elevation = {
      type: "FeatureCollection",
      features: features_elevation,
    };
    const elevation_id = "geojson_elevation";
    if (!map_object.getSource(elevation_id)) {
      map_object.addSource(elevation_id, {
        type: "geojson",
        data: geojson_elevation,
        cluster: false,
      });
    } else {
      map_object.getSource(elevation_id).setData(geojson_elevation);
    }
    if (!map_object.getLayer(elevation_id)) {
      map_object.addLayer({
        id: elevation_id,
        source: elevation_id,
        type: "line",
        paint: { "line-color": "#104585", "line-width": 2, "line-opacity": 1 },
        layout: { visibility: "visible" },
      });
    }
    const geojson_elevation_point = {
      type: "FeatureCollection",
      features: features_elevation_point,
    };
    const elevation_point_id = "geojson_elevation_point";
    if (!map_object.getSource(elevation_point_id)) {
      map_object.addSource(elevation_point_id, {
        type: "geojson",
        data: geojson_elevation_point,
        cluster: false,
      });
    } else {
      map_object.getSource(elevation_point_id).setData(geojson_elevation_point);
    }
    if (!map_object.getLayer("geojson_elevation_point")) {
      map_object.addLayer({
        id: "geojson_elevation_point",
        source: elevation_point_id,
        type: "circle",
        paint: {
          "circle-color": "#0ca5eb",
          "circle-radius": 3,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#ffffff",
        },
        layout: { visibility: "visible" },
      });
    }
    features_elevation_center.forEach((feature, idx) => {
      create_marker_element({
        feature,
        idx,
        uuid_active: uuid_elevation,
        initial: "E",
        map_object,
      });
    });
  };

  on_render_area = () => {
    const { map_object } = this.props.layer;
    const { features_area, features_area_center, uuid_area } =
      this.props.toolbox;
    const geojson_area = {
      type: "FeatureCollection",
      features: features_area,
    };
    const area_id = "geojson_area";
    if (!map_object.getSource(area_id)) {
      map_object.addSource(area_id, {
        type: "geojson",
        data: geojson_area,
        cluster: false,
      });
    } else {
      map_object.getSource(area_id).setData(geojson_area);
    }
    if (!map_object.getLayer(area_id)) {
      map_object.addLayer({
        id: area_id,
        source: area_id,
        type: "fill",
        paint: {
          "fill-color": "#00ff00",
          "fill-opacity": 0.2,
          "fill-outline-color": "#019371",
        },
        layout: {
          visibility: "visible",
        },
      });
    }
    if (!map_object.getLayer("geojson_area_line")) {
      map_object.addLayer({
        id: "geojson_area_line",
        source: area_id,
        type: "line",
        paint: {
          "line-color": "#019371",
          "line-width": 2,
          "line-opacity": 1,
        },
        layout: {
          visibility: "visible",
        },
      });
    }
    features_area_center.forEach((feature, idx) => {
      create_marker_element({
        feature,
        idx,
        uuid_active: uuid_area,
        initial: "A",
        map_object,
      });
    });
  };

  on_render_radius = () => {
    const { map_object } = this.props.layer;
    const { features_radius, uuid_radius } = this.props.toolbox;
    const geojson_radius_polygon = {
      type: "FeatureCollection",
      features: features_radius.map((feature_point) =>
        circle(feature_point, feature_point.properties.radius_km, {
          steps: 100,
          units: "kilometers",
          properties: feature_point.properties,
        })
      ),
    };
    const radius_id = "geojson_radius";
    if (!map_object.getSource(radius_id)) {
      map_object.addSource(radius_id, {
        type: "geojson",
        data: geojson_radius_polygon,
      });
    } else {
      map_object.getSource(radius_id).setData(geojson_radius_polygon);
    }
    if (!map_object.getLayer(radius_id)) {
      map_object.addLayer({
        id: radius_id,
        source: radius_id,
        type: "fill",
        paint: {
          "fill-color": "#3d3f92",
          "fill-opacity": 0.2,
          "fill-outline-color": "#019371",
        },
        layout: { visibility: "visible" },
      });
    }
    if (!map_object.getLayer("geojson_radius_line")) {
      map_object.addLayer({
        id: "geojson_radius_line",
        source: radius_id,
        type: "line",
        paint: { "line-color": "#0ca5eb", "line-width": 2, "line-opacity": 1 },
        layout: { visibility: "visible" },
      });
    }
    features_radius.forEach((feature, idx) => {
      create_marker_element({
        feature,
        idx,
        uuid_active: uuid_radius,
        initial: "R",
        map_object,
      });
    });
  };

  on_render_isochrone = () => {
    const { map_object } = this.props.layer;
    const { features_isokron, features_isokron_center } = this.props.toolbox;
    const geojson_isokron = {
      type: "FeatureCollection",
      features: features_isokron,
    };
    const geojson_isokron_center = {
      type: "FeatureCollection",
      features: features_isokron_center,
    };
    const isochrone_id = "geojson_isokron";
    const isochrone_center_id = "geojson_isokron_center";
    if (!map_object.getSource(isochrone_id)) {
      map_object.addSource(isochrone_id, {
        type: "geojson",
        data: geojson_isokron,
      });
    } else {
      map_object.getSource(isochrone_id).setData(geojson_isokron);
    }
    if (!map_object.getSource(isochrone_center_id)) {
      map_object.addSource(isochrone_center_id, {
        type: "geojson",
        data: geojson_isokron_center,
      });
    } else {
      map_object.getSource(isochrone_center_id).setData(geojson_isokron_center);
    }
    if (!map_object.getLayer(isochrone_id)) {
      map_object.addLayer({
        id: isochrone_id,
        source: isochrone_id,
        type: "fill",
        paint: {
          "fill-color": ["get", "color"],
          "fill-opacity": 0.2,
          "fill-outline-color": "#019371",
        },
        layout: { visibility: "visible" },
      });
    }
    if (!map_object.getLayer("geojson_isokron_line")) {
      map_object.addLayer({
        id: "geojson_isokron_line",
        source: isochrone_id,
        type: "line",
        paint: {
          "line-color": ["get", "color"],
          "line-width": 2,
          "line-opacity": 1,
        },
        layout: { visibility: "visible" },
      });
    }
    if (!map_object.getLayer(isochrone_center_id)) {
      map_object.addLayer({
        id: isochrone_center_id,
        source: isochrone_center_id,
        type: "circle",
        paint: {
          "circle-radius": 6,
          "circle-color": "#ff0000",
          "circle-stroke-width": 2,
          "circle-stroke-color": "#ffffff",
        },
        layout: { visibility: "visible" },
      });
    }
    features_isokron.forEach((feature) => {
      create_marker_isokron({ feature, map_object });
    });
  };

  render() {
    //local storage

    //local state

    //global props

    //content
    return <main></main>;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  toolbox: state.toolbox,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LAYER_TOOLBOX);
