/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import bbox from "@turf/bbox";
import area from "@turf/area";
import geojson_geometries_lookup from "geojson-geometries-lookup";
import points_within_polygon from "@turf/points-within-polygon";

/*PERSONAL COMPONENT*/
import ErrorFree from "../user/ErrorFree";
import Modal from "../common_modal/Modal";
import ProgressBar from "../common_spinner/ProgressBar";

/*REDUX FUNCTION*/
import { set_value_layer } from "../../App/actions/layerActions";
import { importLayer } from "../../App/actions/layerNewActions";
import { set_value_user } from "../../App/actions/authActions";
import { set_value_properties } from "../../App/actions/propertiesActions";
import {
  set_value_insight,
  insight_update,
  push_value_insight,
} from "../../App/actions/insight_v2";

/*PICTURE ASSET*/

/*GENERAL*/

/*NON IMPORT*/

class INPUT_GRID extends Component {
  constructor(props) {
    super(props);
    this.state = {
      km_grid: 1,
      count_grid: 500,
      is_loading: false,
      total_grid: 1,
      current_grid: 0,
      current_overlay: 0,
      loading_status_grid: false,
      loading_status_overlay: false,
      modal_payment: false,
    };
  }

  toggle_login = () => {
    const { modal_login } = this.props.auth;
    this.props.set_value_user("modal_login", !modal_login);
  };

  toggle_payment = () => {
    this.setState({ modal_payment: !this.state.modal_payment });
  };

  clear_grid_intersections = (features_grid_raw) => {
    this.setState({ loading_status_grid: true });
    const {
      features_demography,
      features_poi,
      tipe_1_array,
      tipe_2_array,
      tipe_3_array,
      tipe_level,
      tipe_1_all,
    } = this.props.insight_reducer;

    let array_formula_insight = [];
    if (tipe_level === "tipe_1" || tipe_1_all) {
      tipe_1_array.forEach((item) => {
        const key = item.TIPE_1;
        array_formula_insight.push({
          key,
          is_directly_proportional: true,
          min: 0,
          max: 0,
          weight_score: 1,
          group: "poi",
        });
      });
    } else if (tipe_level === "tipe_2") {
      tipe_2_array.forEach((item) => {
        const key = item.TIPE_2 + ", " + item.TIPE_1;
        array_formula_insight.push({
          key,
          is_directly_proportional: true,
          min: 0,
          max: 0,
          weight_score: 1,
          group: "poi",
        });
      });
    } else if (tipe_level === "tipe_3") {
      tipe_3_array.forEach((item) => {
        const key = item.TIPE_3 + ", " + item.TIPE_2 + ", " + item.TIPE_1;
        array_formula_insight.push({
          key,
          is_directly_proportional: true,
          min: 0,
          max: 0,
          weight_score: 1,
          group: "poi",
        });
      });
    }
    this.props.set_value_insight({
      key: "array_formula_insight",
      value: array_formula_insight,
    });

    const geojson_poi = {
      type: "FeatureCollection",
      features: features_poi,
    };
    const delay_milisecond_constant = 77;
    const delay_promise = () =>
      new Promise((res) => setTimeout(res, delay_milisecond_constant));
    const total_item = features_grid_raw.length;
    const item_per_looping = 5;
    const group_per_looping = [];
    const geojson_demography = {
      type: "FeatureCollection",
      features: features_demography,
    };
    const glookup = new geojson_geometries_lookup(geojson_demography);

    function get_mid_point(coordinates) {
      const numPoints = coordinates.length;
      let lat_sum = 0;
      let long_sum = 0;
      // Sum all latitude and longitude values of the vertices
      coordinates.forEach(([longitude, latitude]) => {
        lat_sum += latitude;
        long_sum += longitude;
      });
      // Calculate the average to get the center point
      const lat_center = lat_sum / numPoints;
      const long_center = long_sum / numPoints;
      return {
        type: "Point",
        coordinates: [long_center, lat_center],
      };
    }

    for (let i = 0; i < total_item; i += item_per_looping) {
      group_per_looping.push({
        start: i,
        end: Math.min(i + item_per_looping, total_item),
      });
    }

    this.props.set_value_insight({
      key: "current_grid_group",
      value: 0,
    });
    this.props.set_value_insight({
      key: "total_grid_group",
      value: group_per_looping.length,
    });

    const parent_function = () => {
      return group_per_looping.reduce(
        (last_promise, range, index) =>
          last_promise.then((result_sum) =>
            child_function(range, index).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };

    const child_function = async (range, index) => {
      return delay_promise().then(() => {
        const core_function = async () => {
          try {
            const items = features_grid_raw.slice(range.start, range.end);
            items.forEach((feature_grid) => {
              const grid_geojson = get_mid_point(
                feature_grid.geometry.coordinates[0] //Pass entire coordinates array
              );
              const geojson_filtered = glookup.getContainers(grid_geojson);
              if (geojson_filtered?.features?.length > 0) {
                //step 1: demography --> next interpolate by area grid vs area DESA
                const properties_demography =
                  geojson_filtered?.features?.[0]?.properties || {};
                //step 2: poi
                const geojson_poi_inside = points_within_polygon(
                  geojson_poi,
                  feature_grid
                );
                const features_poi_inside = geojson_poi_inside?.features || [];
                const ALL_POI_COUNT = geojson_poi_inside?.features?.length || 0;
                let properties_poi = {};
                /*
                 tipe_1_array,
                 tipe_2_array,
                 tipe_3_array,
                 tipe_level,
                 tipe_1_all,


                tipe_level === "tipe_1"
                TIPE_1: Number

                tipe_level === "tipe_2"
                TIPE_2, TIPE_1: Number

                tipe_level === "tipe_3"
                TIPE_3, TIPE_2, TIPE_1: Number

                separator: ", " 
                */
                if (tipe_level === "tipe_1" || tipe_1_all) {
                  tipe_1_array.forEach((item) => {
                    const features_poi_inside_type = features_poi_inside.filter(
                      (feature) => {
                        return feature.properties.TIPE_1 === item.TIPE_1;
                      }
                    );
                    const value = features_poi_inside_type.length;
                    const key = item.TIPE_1;
                    properties_poi[key] = value;
                  });
                } else if (tipe_level === "tipe_2") {
                  tipe_2_array.forEach((item) => {
                    const features_poi_inside_type = features_poi_inside.filter(
                      (feature) => {
                        return (
                          feature.properties.TIPE_1 === item.TIPE_1 &&
                          feature.properties.TIPE_2 === item.TIPE_2
                        );
                      }
                    );
                    const value = features_poi_inside_type.length;
                    const key = item.TIPE_2 + ", " + item.TIPE_1;
                    properties_poi[key] = value;
                  });
                } else if (tipe_level === "tipe_3") {
                  tipe_3_array.forEach((item) => {
                    const features_poi_inside_type = features_poi_inside.filter(
                      (feature) => {
                        return (
                          feature.properties.TIPE_1 === item.TIPE_1 &&
                          feature.properties.TIPE_2 === item.TIPE_2 &&
                          feature.properties.TIPE_3 === item.TIPE_3
                        );
                      }
                    );
                    const value = features_poi_inside_type.length;
                    const key =
                      item.TIPE_3 + ", " + item.TIPE_2 + ", " + item.TIPE_1;
                    properties_poi[key] = value;
                  });
                }
                feature_grid.properties = {
                  ...feature_grid.properties,
                  ...properties_demography,
                  ...properties_poi,
                  ALL_POI_COUNT,
                };
                this.props.push_value_insight({
                  key: "features_grid",
                  value: feature_grid,
                });
              }
            });
            this.props.set_value_insight({
              key: "current_grid_group",
              value: index + 1,
            });
          } catch (error) {
            console.error(error);
          }
        };
        return core_function();
      });
    };

    parent_function().then(() => {
      this.setState({ loading_status_grid: false });
      this.props.insight_update();
    });
  };

  generate_square = () => {
    // Part 1: Clear features_grid and trigger insight update
    this.props.set_value_insight({
      key: "features_grid",
      value: [],
    });
    this.props.insight_update();

    // Part 2: Execute after a 1000-millisecond delay
    setTimeout(() => {
      const grid_counts = 200;
      const { features_demography } = this.props.insight_reducer;
      const geojson_demography = {
        type: "FeatureCollection",
        features: features_demography,
      };
      const demografi_area = area(geojson_demography); // in square meters
      const [min_longitude, min_latitude, max_longitude, max_latitude] =
        bbox(geojson_demography);
      const width = max_longitude - min_longitude; // Calculate the width and height of the bounding box in degrees
      const height = max_latitude - min_latitude;
      const width_meters = width * 111000; // Convert width and height to approximate meters
      const height_meters = height * 111000;
      const bounding_box_area = width_meters * height_meters; // Calculate the bounding box area in square meters
      const initial_cellArea = bounding_box_area / grid_counts; // Calculate the initial cell area based on bounding box area and fixed grid count
      const adjusted_cellArea =
        initial_cellArea * (demografi_area / bounding_box_area); // Adjust the cell area to fit the demography area more accurately
      const cellWidth = Math.sqrt(adjusted_cellArea); // in meters
      const cellHeight = cellWidth; // in meters (to maintain square shape)
      const grid_size = [cellWidth, cellHeight];
      const cellWidth_deg = cellWidth / 111000; // Convert cell width and height back to degrees for grid generation
      const cellHeight_deg = cellHeight / 111000;
      const features_grid_raw = []; // Create the grid features
      const numRows = Math.ceil(height / cellHeight_deg);
      const numCols = Math.ceil(width / cellWidth_deg);

      for (let row = 0; row < numRows; row++) {
        // Loop through rows and columns to generate grid cells
        for (let col = 0; col < numCols; col++) {
          const cellMinLng = min_longitude + col * cellWidth_deg;
          const cellMinLat = min_latitude + row * cellHeight_deg;
          const cellMaxLng = cellMinLng + cellWidth_deg;
          const cellMaxLat = cellMinLat + cellHeight_deg;

          // Create the grid cell as a GeoJSON Polygon feature
          const cell_feature = {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [cellMinLng, cellMinLat],
                  [cellMaxLng, cellMinLat],
                  [cellMaxLng, cellMaxLat],
                  [cellMinLng, cellMaxLat],
                  [cellMinLng, cellMinLat],
                ],
              ],
            },
            properties: {
              row,
              col,
            },
          };
          features_grid_raw.push(cell_feature);
        }
      }

      // Update insight with the generated features_grid_raw and grid_size
      this.props.set_value_insight({
        key: "features_grid_raw",
        value: features_grid_raw,
      });
      this.props.set_value_insight({
        key: "grid_size",
        value: grid_size,
      });

      // Clear grid intersections
      this.clear_grid_intersections(features_grid_raw);
    }, 100); //milliseconds delay
  };

  generate_hex = () => {
    // Part 1: Clear features_grid and trigger insight update
    this.props.set_value_insight({
      key: "features_grid",
      value: [],
    });
    this.props.insight_update();

    // Part 2: Execute after a 1000-millisecond delay
    setTimeout(() => {
      const grid_counts = 200;
      const { features_demography } = this.props.insight_reducer;
      const geojson_demography = {
        type: "FeatureCollection",
        features: features_demography,
      };
      const demografi_area = area(geojson_demography); // in square meters
      const [min_longitude, min_latitude, max_longitude, max_latitude] =
        bbox(geojson_demography);
      // Calculate the width and height of the bounding box in degrees
      const width = max_longitude - min_longitude;
      const height = max_latitude - min_latitude;
      const width_meters = width * 111000; // Convert width and height to approximate meters
      const height_meters = height * 111000;
      const bounding_box_area = width_meters * height_meters; // Calculate the bounding box area in square meters
      const initial_cellArea = bounding_box_area / grid_counts; // Calculate the initial cell area based on bounding box area and fixed grid count
      const adjusted_cellArea =
        initial_cellArea * (demografi_area / bounding_box_area); // Adjust the cell area to fit the demography area more accurately
      const hexRadius = Math.sqrt((2 * adjusted_cellArea) / (3 * Math.sqrt(3))); // in meters

      // Hexagon dimensions
      const hexWidth = 2 * hexRadius; // Horizontal distance across hexagon
      const hexHeight = Math.sqrt(3) * hexRadius; // Vertical distance across hexagon
      const grid_size = [hexWidth, hexHeight];
      const hexWidth_deg = hexWidth / 111000; // Convert hex width and height back to degrees for grid generation
      const hexHeight_deg = hexHeight / 111000;
      const features_grid_raw = []; // Create the hexagonal grid features
      const numRows = Math.ceil(height / hexHeight_deg);
      const numCols = Math.ceil(width / (hexWidth_deg * 0.75)); // offset columns by 3/4 width for hex pattern

      for (let row = 0; row < numRows; row++) {
        // Loop through rows and columns to generate hexagonal grid cells
        for (let col = 0; col < numCols; col++) {
          const centerLng = min_longitude + col * hexWidth_deg * 0.75; // Calculate hex center position
          let centerLat = min_latitude + row * hexHeight_deg;
          // Offset every second row by half the hex width to create the staggered hex pattern
          if (col % 2 === 1) {
            centerLat += hexHeight_deg / 2;
          }
          // Define hexagon points based on center point and hex radius
          const hex_points = [];
          for (let i = 0; i < 6; i++) {
            const angle = (Math.PI / 3) * i; // 60 degrees for each hex side
            const pointLng = centerLng + (hexRadius / 111000) * Math.cos(angle);
            const pointLat = centerLat + (hexRadius / 111000) * Math.sin(angle);
            hex_points.push([pointLng, pointLat]);
          }
          hex_points.push(hex_points[0]); // close the hexagon
          // Create the hex cell as a GeoJSON Polygon feature
          const cell_feature = {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [hex_points],
            },
            properties: {
              row,
              col,
            },
          };
          features_grid_raw.push(cell_feature);
        }
      }

      // Update insight with the generated features_grid_raw and grid_size
      this.props.set_value_insight({
        key: "features_grid_raw",
        value: features_grid_raw,
      });
      this.props.set_value_insight({
        key: "grid_size",
        value: grid_size,
      });

      // Clear grid intersections
      this.clear_grid_intersections(features_grid_raw);
    }, 1000); // 1000 milliseconds delay
  };

  render() {
    const { modal_payment, loading_status_grid } = this.state;

    const { quota_access, isAuthenticated } = this.props.auth;
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const { total_grid_group, current_grid_group } = this.props.insight_reducer;

    let button_content;
    if (
      license_user_status?.is_has_license ||
      license_group_status?.is_has_license ||
      quota_access > 0
    ) {
      button_content = (
        <section>
          <button
            className="button margin_bottom"
            onClick={this.generate_square}
            data-mapid="clickArea"
          >
            Rekomendasi grid square
          </button>
          <br />
          <button
            className="button"
            onClick={this.generate_hex}
            data-mapid="clickArea"
          >
            Rekomendasi grid heksagon
          </button>
        </section>
      );
    } else if (!isAuthenticated) {
      button_content = (
        <div className="button" id="grey" onClick={this.toggle_login}>
          Rekomendasi grid
        </div>
      );
    } else {
      button_content = (
        <div className="button" id="grey" onClick={this.toggle_payment}>
          Rekomendasi grid
        </div>
      );
    }

    const modal_payment_content = modal_payment && (
      <Modal
        modalSize="medium"
        id="modal_payment"
        isOpen={modal_payment}
        onClose={this.toggle_payment}
      >
        <div className="box-body">
          <ErrorFree />
        </div>
      </Modal>
    );

    return (
      <main className="margin_bottom_extra">
        {modal_payment_content}
        <section className="container_light outline_transparent background_grey_light">
          <section className="margin_bottom">
            <div className="badge_pill background_white">Step 2: Atur grid</div>
          </section>
          {button_content}
          {loading_status_grid && (
            <section className="margin_top">
              <ProgressBar
                current_number={current_grid_group}
                total_number={total_grid_group}
                name="Membuat grid"
              />
            </section>
          )}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  map: state.map,
  project: state.project,
  payment: state.payment,
  license_reducer: state.license_reducer,
  loading_reducer: state.loading_reducer,
  insight_reducer: state.insight_reducer,
});

export default connect(mapStateToProps, {
  set_value_layer,
  importLayer,
  set_value_user,
  set_value_properties,
  set_value_insight,
  insight_update,
  push_value_insight,
})(INPUT_GRID);
