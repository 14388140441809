/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Resizable } from "re-resizable";
import { Helmet } from "react-helmet";

/*PERSONAL COMPONENT*/
import INPUT_DATA from "../../Components/insight/INPUT_DATA";
import INPUT_GRID from "../../Components/insight/INPUT_GRID";
import INPUT_FORMULA from "../../Components/insight/INPUT_FORMULA";
import INPUT_FORMULA_SHORT from "../../Components/insight/INPUT_FORMULA_SHORT";
import RESULT_INSIGHT from "../../Components/insight/RESULT_INSIGHT";

import MAP_COMPONENT from "../../Components/libre_layer/MAP_COMPONENT";
import LayerListModeExpand from "../../Components/editor_sidebar/LayerListModeExpand";
import EditorProperties from "../../Components/editor_table/EditorProperties";
import RIGHT_SIDEBAR from "../../Components/sidebar_map_right/RIGHT_SIDEBAR";

/*REDUX FUNCTION*/
import {
  setLanguage,
  get_quota_access,
  set_modal_quota_access,
} from "../../App/actions/authActions";
import { get_history_project } from "../../App/actions/projectActions";
import {
  setStatisticMode,
  setMapMode,
} from "../../App/actions/layerNewActions";
import { set_value_properties } from "../../App/actions/propertiesActions";
import { set_value_user, logoutUser } from "../../App/actions/authActions";
import { clearProjectList } from "../../App/actions/projectActions";
import { clearFormList } from "../../App/actions/layerNewActions";
import {
  verify_license_user,
  verify_license_group,
} from "../../App/actions/license_actions";
import { set_value_layer } from "../../App/actions/layerActions";

/*PICTURE ASSET*/
import icon_insight from "../../Assets/svg/icon_insight.svg";
import icon_coin from "../../Assets/svg/icon_coin.svg";

/*GENERAL*/
import poi_list from "../../Data/Poi_List_Type_1.json";
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class PARENT_INSIGHT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boxStatisticStatus: false,
      openStatus: false,
      all_poi: poi_list,
      select_sort: "ascending",
      select_name: "label",
      tableComponent: {
        height: 0,
      },
    };
  }

  componentDidMount() {
    this.props.set_value_layer({
      key: "page_layer_mode",
      value: "editor",
    });
    const { isAuthenticated } = this.props.auth;
    if (isAuthenticated) {
      this.props.verify_license_user();
      this.props.verify_license_group();
      this.props.get_history_project();
      this.props.get_quota_access();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tableComponent.height !== this.state.tableComponent.height) {
      if (document?.getElementById("statistic_resume_box")?.style?.height) {
        document.getElementById(
          "statistic_resume_box"
        ).style.height = `calc((100vh - 355px - ${this.state.tableComponent.height}px))`;
      }
    }

    if (this.props.layer.tableStatus) {
      const height = document?.getElementById("tableBar")?.offsetHeight;
      if (height) {
        localStorage.setItem("table_height", height);
      }
    } else {
      localStorage.setItem("table_height", 0);
    }
  }

  toggle_login = () => {
    const { modal_login } = this.props.auth;
    this.props.set_value_user("modal_login", !modal_login);
  };

  onLogoutClick = () => {
    this.props.logoutUser();
    this.props.clearProjectList();
    this.props.clearFormList();
  };

  toggle_buy_license = () => {
    const { modal_buy_license } = this.props.properties;
    this.props.set_value_properties({
      key: "modal_buy_license",
      value: !modal_buy_license,
    });
  };

  toggle_modal_quota_price = () => {
    this.props.set_modal_quota_access(false);
  };

  setTableComponent = (d) => {
    const height = document?.getElementById("tableBar")?.offsetHeight;
    if (height) {
      this.setState({
        tableComponent: {
          ...this.state.tableComponent,
          height: height,
        },
      });
      localStorage.setItem("table_height", height);
    }
  };

  setRightSideBarComponent = () => {
    const width = document?.getElementById("statistic_resume_box")?.offsetWidth;
    if (width) {
      this.setState({
        tableComponent: {
          ...this.state.RightSideBarComponent,
          width: width,
        },
      });
      localStorage.setItem("rightbar_width", width);
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";
    const width_screen = window.innerWidth;

    //local state

    //global props
    const {
      loadingList,
      loadingProcess,
      itemLoading,
      chatStatus,
      realtimeStatus,
    } = this.props.layer;
    const { geo_project, loadingDetail } = this.props.project;
    const { quota_access, modal_quota_access, isAuthenticated } =
      this.props.auth;
    const { sidebar_right_status } = this.props.properties;
    const { sidebar_content } = this.props.insight_reducer;

    //content
    let textLoading = "Saving";
    switch (itemLoading) {
      case "createLayer":
        textLoading = dict["Creating layer"][language];
        break;
      case "importLayer":
        textLoading = dict["Importing layer"][language];
        break;
      case "uploadLayer":
        textLoading = dict["Importing layer"][language];
        break;
      case "edit_general":
        textLoading = dict["Saving changes"][language];
        break;
      case "editAllFields":
        textLoading = dict["Saving changes"][language];
        break;
      case "editLayerForm":
        textLoading = dict["Saving changes"][language];
        break;
      case "pushFeature":
        textLoading = dict["Adding feature"][language];
        break;
      case "push_project_attach":
        textLoading = dict["Attaching layer"][language];
        break;
      case "delete_project_attach":
        textLoading = dict["Deleting attached layer"][language];
        break;
      case "edit_geometry":
        textLoading = dict["Saving geometry changes"][language];
        break;
      case "edit_properties":
        textLoading = dict["Saving attribute value"][language];
        break;
      case "delete_feature":
        textLoading = dict["Deleting"][language];
        break;
      case "hide_feature":
        textLoading = dict["Hiding feature"][language];
        break;
      case "push_field":
        textLoading = dict["Adding column"][language];
        break;
      case "editField":
        textLoading = dict["Saving column"][language];
        break;
      case "deleteField":
        textLoading = dict["Deleting column"][language];
        break;
      case "delete_layer":
        textLoading = dict["Deleting layer"][language];
        break;
      case "get_detail":
        textLoading = dict["Getting GIS data"][language];
        break;
      case "genangan":
        textLoading = "Menjalankan algoritma genangan";
        break;
      case "deleteFieldForm":
        textLoading = "Menghapus kolom tabel";
        break;
      case "move_layers_into_any_folder":
        textLoading = dict["Moving layers"][language];
        break;
      case "delete_layers_bulk":
        textLoading = dict["Deleting layers"][language];
        break;
      default:
        textLoading = itemLoading;
        break;
    }

    const input_content = (
      <main
        style={{
          zIndex: 1,
          overflowY: "auto",
          height: "100%",
          width: "100%",
          paddingRight: "20px",
          boxSizing: "border-box",
        }}
      >
        {sidebar_content === "home" ? (
          <section>
            <table className="full_width">
              <tbody>
                <tr>
                  <td>
                    <Link to={"/"}>
                      <img
                        src={icon_insight}
                        height="27px"
                        alt="MAPID INSIGHT"
                      />
                    </Link>
                  </td>
                  <td>
                    <div
                      className="button_pill background_teal"
                      onClick={() =>
                        this.props.set_modal_quota_access(!modal_quota_access)
                      }
                    >
                      <img
                        src={icon_coin}
                        alt="coin"
                        className="inline margin_right"
                        height="20px"
                      />
                      <p className="inline text_medium">{quota_access}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            {!isAuthenticated ? (
              <div
                className="button_pill background_teal"
                onClick={this.toggle_login}
              >
                <img
                  src={icon_coin}
                  alt="coin"
                  className="inline margin_right"
                  height="20px"
                />
                <p className="inline text_medium">Register</p>
              </div>
            ) : (
              <div
                className="button_pill background_teal"
                onClick={this.onLogoutClick.bind(this)}
              >
                <img
                  src={icon_coin}
                  alt="coin"
                  className="inline margin_right"
                  height="20px"
                />
                <p className="inline text_medium">Log out</p>
              </div>
            )}
            <br />
            <br />
            <INPUT_DATA />
            <INPUT_GRID />
            <INPUT_FORMULA />
            <RESULT_INSIGHT />
            <section className="container_light outline_transparent background_grey_light">
              <div className="badge_pill background_white margin_bottom">
                Histori
              </div>
              <LayerListModeExpand />
            </section>
            <br />
            <br />
            <br />
          </section>
        ) : (
          <INPUT_FORMULA_SHORT />
        )}
      </main>
    );

    let table_content;

    if (this.props.layer.tableStatus) {
      if (width_screen > 900) {
        table_content = (
          <Resizable
            onResizeStop={(e, direction, ref, d) => {
              this.setTableComponent(d);
            }}
            id="tableBar"
            boundsByDirection={true}
            defaultSize={{
              width: "100%",
              height: 300,
            }}
            maxHeight="100vh"
            minHeight="75px"
            minWidth="calc((100% - 355px))"
            maxWidth="calc((100% - 355px))"
            style={{
              position: "fixed",
              bottom: 0,
              right: 0,
              maxHeight: window.innerHeight - 5,
              minHeight: "30px",
              zIndex: 1,
              backgroundColor: "#ffffff",
              color: "#1e5e96ff",
            }}
          >
            <EditorProperties />
          </Resizable>
        );
      } else {
        table_content = (
          <Resizable
            onResizeStop={(e, direction, ref, d) => {
              this.setTableComponent(d);
            }}
            defaultSize={{
              width: "100%",
              height: "500px",
            }}
            minWidth="100%"
            maxWidth="100%"
            minHeight="100px"
            maxHeight="100vh"
            style={{
              position: "fixed",
              width: "100%",
              height: "500px",
              zIndex: 1,
              left: "0px",
              bottom: "0px",
              backgroundColor: "white",
              paddingRight: "0px",
              paddingLeft: "20px",
              borderRadius: "20px 20px 0px 0px",
            }}
          >
            <EditorProperties />
          </Resizable>
        );
      }
    }

    return (
      <main>
        {!!textLoading && !chatStatus && !realtimeStatus && loadingProcess && (
          <div
            style={{
              zIndex: "20",
              backgroundColor: "#0ca5eb",
              color: "#fff",
              position: "fixed",
              top: "0px",
              right: "0px",
              width: "100vw",
              textAlign: "center",
              paddingTop: "5px",
              paddingBottom: "5px",
              boxShadow: "0px 0px 7px 7px rgba(0, 0, 0, 0.09)",
              fontSize: "15px",
            }}
          >
            {textLoading}
          </div>
        )}
        <MAP_COMPONENT is_editable={true} />

        {sidebar_right_status && (
          <RIGHT_SIDEBAR
            loadingList={loadingList}
            loadingDetail={loadingDetail}
            geo_project={geo_project}
            language={language}
            tableComponent={this.state.tableComponent}
          />
        )}

        {width_screen > 900 ? (
          <Resizable
            onResizeStop={(e, direction, ref, d) => {
              this.setRightSideBarComponent(d);
            }}
            defaultSize={{
              width: "355px",
              height: "100vh",
            }}
            minWidth="355px"
            maxWidth="80vw"
            minHeight="100vh"
            maxHeight="100vh"
            style={{
              zIndex: 1,
              left: 0,
              bottom: 0,
              backgroundColor: "white",
              paddingRight: "0px",
              paddingLeft: "20px",
              width: "355px",
              height: "100vh",
            }}
          >
            {input_content}
          </Resizable>
        ) : (
          <Resizable
            onResizeStop={(e, direction, ref, d) => {
              this.setRightSideBarComponent(d);
            }}
            defaultSize={{
              width: "100%",
              height: "500px",
            }}
            minWidth="100%"
            maxWidth="100%"
            minHeight="100px"
            maxHeight="100vh"
            style={{
              position: "fixed",
              width: "100%",
              height: "500px",
              zIndex: 1,
              left: "0px",
              bottom: "0px",
              backgroundColor: "white",
              paddingRight: "0px",
              paddingLeft: "20px",
              borderRadius: "20px 20px 0px 0px",
            }}
          >
            {input_content}
          </Resizable>
        )}

        {table_content}

        <style>{`
    body{
      padding-top: 0px;
      overflow-y: hidden;
    }
    #navbarDefault{ visibility: hidden;}
    .top_nav{ visibility: hidden;}
    .sidebar_home{ visibility: hidden;}
    .nav_bottom{ visibility: hidden;}
 `}</style>

        <Helmet>
          <title>INSIGHT</title>
          <meta name="description" content="INSIGHT" />
        </Helmet>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  map: state.map,
  layer: state.layer,
  project: state.project,
  payment: state.payment,
  properties: state.properties,
  license_reducer: state.license_reducer,
  insight_reducer: state.insight_reducer,
});

export default connect(mapStateToProps, {
  setLanguage,
  get_history_project,
  setStatisticMode,
  setMapMode,
  get_quota_access,
  set_modal_quota_access,
  set_value_properties,
  set_value_user,
  logoutUser,
  clearProjectList,
  clearFormList,
  verify_license_user,
  verify_license_group,
  set_value_layer,
})(PARENT_INSIGHT);
