/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import INPUT_FORMULA_CONTENT from "./INPUT_FORMULA_CONTENT";

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class INPUT_FORMULA extends Component {
  state = {
    modal_open_formula: false,
  };

  toggle_open_formula = () => {
    this.setState({ modal_open_formula: !this.state.modal_open_formula });
  };

  render() {
    //local storage

    //local state
    const { modal_open_formula } = this.state;

    //global props

    //content
    const modal_open_formula_content = modal_open_formula && (
      <Modal
        modalSize="large"
        id="modal_open_formula"
        isOpen={modal_open_formula}
        onClose={this.toggle_open_formula}
      >
        <div className="box-body" id="box-body">
          <INPUT_FORMULA_CONTENT
            toggle_open_formula={this.toggle_open_formula}
          />
        </div>
      </Modal>
    );

    return (
      <main className="margin_bottom_extra">
        {modal_open_formula_content}
        <section className="container_light outline_transparent background_grey_light">
          <section className="margin_bottom">
            <div className="badge_pill background_white">
              Step 3: Atur Formula
            </div>
          </section>
          <button className="button" onClick={this.toggle_open_formula}>
            Buat Formula
          </button>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  insight_reducer: state.insight_reducer,
});

export default connect(mapStateToProps, {})(INPUT_FORMULA);
