/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import ProgressBar from "../common_spinner/ProgressBar";
import InputAreaSingle from "./InputAreaSingle";
import InputAreaHirarki from "./InputAreaHirarki";
import INPUT_TIPE_SHORT from "./INPUT_TIPE_SHORT";
import INPUT_TIPE_FULL from "./INPUT_TIPE_FULL";

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { set_value_user } from "../../App/actions/authActions";
import { get_insight_v2 } from "../../App/actions/insight_v2";

/*PICTURE ASSET*/
import expand from "../../Assets/svg/expand.svg";

/*GENERAL FUNCTION & DATA*/
import uuid from "../../App/validation/uuid";
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class INPUT_DATA extends Component {
  state = {
    mode_input: "single_input", //hirarki || single_input
    modal_payment: false,
    modal_choose_poi: false,
  };

  toggle_login = () => {
    const { modal_login } = this.props.auth;
    this.props.set_value_user("modal_login", !modal_login);
  };

  toggle_payment_universal = () => {
    const { modal_payment_universal } = this.props.auth;
    this.props.set_value_user(
      "modal_payment_universal",
      !modal_payment_universal
    );
  };

  on_set_mode_input = (mode_input) => {
    this.setState({ mode_input });
  };

  toggle_choose_poi = () => {
    this.setState({ modal_choose_poi: !this.state.modal_choose_poi });
  };

  toggle_payment = () => {
    const { modal_payment_universal } = this.props.auth;
    this.props.set_value_user(
      "modal_payment_universal",
      !modal_payment_universal
    );
  };

  on_get_insight = () => {
    const { isAuthenticated, quota_access } = this.props.auth;
    const quota_access_sini_ai = this.props.auth.quota_access_sini_ai || 0;
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const payment_type = license_user_status?.payment_type;
    const logic_1 = !["license_1", "license_2", "license_3"].includes(
      payment_type
    );
    const logic_2 = !license_group_status?.is_has_license;
    const logic_3 = quota_access <= 0;
    const logic_4 = quota_access_sini_ai <= 0;
    if (!isAuthenticated) {
      this.toggle_login();
    } else if (logic_1 && logic_2 && logic_3 && logic_4) {
      this.toggle_payment_universal();
    } else {
      const {
        provinsi,
        kota,
        kecamatan,
        kelurahan,
        tipe_1_array,
        tipe_2_array,
        tipe_3_array,
        tipe_level,
        tipe_1_all,
      } = this.props.insight_reducer;
      const query = {
        request_id: uuid(),
        PROVINSI: provinsi,
        KABKOT: kota,
        KECAMATAN: kecamatan,
        DESA: kelurahan,
        tipe_1_array,
        tipe_2_array,
        tipe_3_array,
        tipe_level,
        tipe_1_all,
      };
      this.props.get_insight_v2(query);
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { mode_input, modal_choose_poi } = this.state;

    //global props
    const {
      kota,
      total_poi,
      current_progress_poi,
      tipe_1_array,
      tipe_2_array,
      tipe_3_array,
      tipe_level,
      tipe_1_all,
      features_poi,
    } = this.props.insight_reducer;
    const { loading_item_array } = this.props.loading_reducer;

    //content
    let button_content;
    if (!kota) {
      button_content = (
        <div className="button" id="grey">
          {dict?.["Search data"]?.[language]}
        </div>
      );
    } else if (loading_item_array.includes("get_insight_v2")) {
      button_content = (
        <div className="button" id="grey">
          {dict?.["Loading"]?.[language]}
        </div>
      );
    } else {
      button_content = (
        <button
          className="button"
          onClick={this.on_get_insight}
          data-mapid="clickCari"
        >
          {dict?.["Search data"]?.[language]}
        </button>
      );
    }

    const modal_choose_poi_content = modal_choose_poi && (
      <Modal
        modalSize="large"
        id="modal_choose_poi"
        isOpen={modal_choose_poi}
        onClose={this.toggle_choose_poi}
      >
        <div className="box-body">
          <INPUT_TIPE_FULL toggle_choose_poi={this.toggle_choose_poi} />
        </div>
      </Modal>
    );

    let input_area_content = <InputAreaSingle />;
    if (mode_input === "hirarki") {
      input_area_content = <InputAreaHirarki />;
    }

    return (
      <main>
        {modal_choose_poi_content}
        <main className="container_light outline_transparent background_grey_light margin_bottom_extra">
          <section className="margin_bottom">
            <div className="badge_pill background_white">
              Step 1: Input Lokasi
            </div>
          </section>
          <section className="margin_bottom">
            <button
              className={`button margin_right margin_bottom ${
                mode_input === "single_input"
                  ? "background_black"
                  : "background_white"
              }`}
              onClick={this.on_set_mode_input.bind(this, "single_input")}
            >
              Pencarian
            </button>
            <button
              className={`button margin_right margin_bottom ${
                mode_input === "hirarki"
                  ? "background_black"
                  : "background_white"
              }`}
              onClick={this.on_set_mode_input.bind(this, "hirarki")}
              data-mapid="clickAdminisTrasi"
            >
              Level Administrasi
            </button>
          </section>
          {input_area_content}
          <br />
          <section>
            <table className="full_width">
              <tbody>
                <tr>
                  <td>
                    <p className="text_bold">
                      Pilih tema lokasi point of interest
                    </p>
                  </td>
                  <td className="text_right">
                    <img
                      className="cursor_pointer"
                      alt="expand"
                      src={expand}
                      style={{ height: "20px" }}
                      onClick={this.toggle_choose_poi}
                      data-mapid="clickExpand"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <INPUT_TIPE_SHORT />
            {tipe_level !== "tipe_1" && (
              <table className="table full_width">
                <tbody>
                  <tr>
                    <td>Level yang dipilih</td>
                    <td>{tipe_1_all ? "ALL" : tipe_level}</td>
                  </tr>
                  {!tipe_1_all && (
                    <tr>
                      <td>Jumlah tipe yang dipilih</td>
                      <td>
                        {tipe_level === "tipe_1" ? (
                          <>{tipe_1_array.length}</>
                        ) : tipe_level === "tipe_2" ? (
                          <>{tipe_2_array.length}</>
                        ) : (
                          <>{tipe_3_array.length}</>
                        )}
                      </td>
                    </tr>
                  )}
                  {!tipe_1_all && (
                    <tr>
                      <td>Tipe yang dipilih</td>
                      <td>
                        <div style={{ maxHeight: "70px", overflowY: "auto" }}>
                          {tipe_level === "tipe_1" ? (
                            <>
                              {tipe_1_array.map((item, idx) => {
                                return (
                                  <div
                                    className="text_small margin_right"
                                    key={idx}
                                  >
                                    {item.TIPE_1},
                                  </div>
                                );
                              })}
                            </>
                          ) : tipe_level === "tipe_2" ? (
                            <>
                              {tipe_2_array.map((item, idx) => {
                                return (
                                  <div
                                    className="text_small margin_right"
                                    key={idx}
                                  >
                                    {item.TIPE_2},
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {tipe_3_array.map((item, idx) => {
                                return (
                                  <div
                                    className="text_small margin_right"
                                    key={idx}
                                  >
                                    {item.TIPE_3},
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </section>
          <br />
          <section className="margin_top">
            {features_poi.length > 0 && (
              <div className="text_small">
                Jumlah titik: {features_poi.length}
              </div>
            )}
            <ProgressBar
              current_number={current_progress_poi}
              total_number={total_poi}
              name="Memuat data"
            />
          </section>
          {button_content}
        </main>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  map: state.map,
  layer: state.layer,
  payment: state.payment,
  properties: state.properties,
  sini: state.sini,
  license_reducer: state.license_reducer,
  insight_reducer: state.insight_reducer,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_properties,
  set_value_user,
  get_insight_v2,
})(INPUT_DATA);
