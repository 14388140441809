/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";

/*PERSONAL COMPONENT*/
import INPUT_DEMOGRAPHY from "./INPUT_DEMOGRAPHY";

/*REDUX FUNCTION*/
import {
  set_value_insight,
  insight_update,
} from "../../App/actions/insight_v2";

/*PICTURE ASSET*/
import ICON_ARROW_LEFT_LINE from "../../Assets/jsx/ICON_ARROW_LEFT_LINE";
import ICON_ARROW_RIGHT_LINE from "../../Assets/jsx/ICON_ARROW_RIGHT_LINE";
import ICON_ARROW_DOWN from "../../Assets/jsx/ICON_ARROW_DOWN";
import ICON_ARROW_UP from "../../Assets/jsx/ICON_ARROW_UP";

/*FUNCTION*/
import run_scoring from "../../Functions/insight/run_scoring";

/*DATA*/

/*NON IMPORT*/

class INPUT_FORMULA_SHORT extends Component {
  state = {
    anchor: null,
    modal_edit_weight: false,
    key_selected: "",
    weight_score_selected: 1,
  };

  on_click_step = (step_formula) => {
    this.props.set_value_insight({
      key: "step_formula",
      value: step_formula,
    });
  };

  on_run_scoring = () => {
    let {
      features_grid,
      array_formula_insight,
      array_group_insight,
      config_final_score,
    } = this.props.insight_reducer;
    run_scoring({
      features_grid,
      array_formula_insight,
      array_group_insight,
      config_final_score,
      set_value_insight: this.props.set_value_insight,
      insight_update: this.props.insight_update,
    });
  };

  on_back = () => {
    this.props.set_value_insight({
      key: "sidebar_content",
      value: "home",
    });
  };

  on_change_weight = (event) => {
    const name = event.target.name;
    const value = Number(event.target.value);
    this.setState(
      {
        weight_score_selected: value,
      },
      () => {
        if (value > 0) {
          const { array_formula_insight } = this.props.insight_reducer;
          const object = array_formula_insight.find(
            (item) => item.key === name
          );
          object.weight_score = value;
          this.props.set_value_insight({
            key: "array_formula_insight",
            value: array_formula_insight,
          });
        }
      }
    );
  };

  toggle_is_directly_proportional = (key) => {
    const { array_formula_insight } = this.props.insight_reducer;
    const object = array_formula_insight.find((item) => item.key === key);
    object.is_directly_proportional = !object.is_directly_proportional;
    this.props.set_value_insight({
      key: "array_formula_insight",
      value: array_formula_insight,
    });
  };

  toggle_edit_weight = (event) => {
    this.setState(
      {
        modal_edit_weight: !this.state.modal_edit_weight,
        anchor: event.currentTarget,
      },
      () => {
        const { modal_edit_weight } = this.state;
        if (modal_edit_weight) {
          const key_selected = event.target.id;
          const { array_formula_insight } = this.props.insight_reducer;
          const object = array_formula_insight.find(
            (item) => item.key === key_selected
          );
          const weight_score_selected = object.weight_score;
          this.setState({ key_selected, weight_score_selected });
        }
      }
    );
  };

  render() {
    //local storage

    //local state
    const { anchor, modal_edit_weight, key_selected, weight_score_selected } =
      this.state;

    //global props
    const { array_formula_insight, array_group_insight, step_formula } =
      this.props.insight_reducer;

    //content
    const modal_edit_weight_content = modal_edit_weight && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_edit_weight}
        onClose={this.toggle_edit_weight}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ borderRadius: "15px" }}
      >
        <div style={{ padding: "7px 15px 0px 15px", width: "100px" }}>
          <input
            type="number"
            name={key_selected}
            value={weight_score_selected}
            onChange={this.on_change_weight}
          />
        </div>
      </Menu>
    );

    return (
      <main className="parent_section_full">
        {modal_edit_weight_content}

        <div className="section_1">
          <section className="two_container_auto margin_bottom">
            <div>
              <button
                className="button_pill background_grey_light"
                onClick={this.on_back}
              >
                <span className="badge_circle background_black margin_right">
                  <ICON_ARROW_LEFT_LINE fill="#fff" />
                </span>
                <span className="margin_right">Kembali</span>
              </button>
            </div>
            <div className="text_right">
              <button
                className="button_big background_black"
                title="Simpan hasil skoring sebagai layer baru"
              >
                Simpan
              </button>
            </div>
          </section>

          {step_formula === 1 ? (
            <div>
              <section className="margin_bottom">
                <h1 className="text_header margin_bottom">
                  Edit dan jalankan formula
                </h1>
                <p className="text_small">
                  Edit parameter di bawah dan klik "Jalankan ulang" untuk
                  melihat perubahan hasil skoring.
                </p>
                <p className="text_small">
                  Klik "Simpan" untuk menyimpan hasil skoring sebagai layer
                  baru.
                </p>
              </section>

              <section className="two_container_auto margin_bottom_extra">
                <div>
                  <button
                    className="button_pill button_white outline_black"
                    onClick={this.on_click_step.bind(this, 0)}
                  >
                    <span className="badge_circle background_black margin_right">
                      <ICON_ARROW_LEFT_LINE fill="#fff" />
                    </span>
                    <span className="margin_right">Edit demografi</span>
                  </button>
                </div>
                <div className="text_right">
                  <button
                    className="button_pill button_white outline_black"
                    onClick={this.on_run_scoring}
                  >
                    <span className="margin_right margin_left">
                      Jalankan formula
                    </span>
                    <span className="badge_circle background_black">
                      <ICON_ARROW_RIGHT_LINE fill="#fff" />
                    </span>
                  </button>
                </div>
              </section>
            </div>
          ) : (
            <div>
              <section className="margin_bottom">
                <h1 className="text_header margin_bottom">
                  Edit parameter demografi
                </h1>
                <p className="text_small">
                  Pilih parameter demografi yang akan dilibatkan dalam skoring
                </p>
              </section>

              <section className="two_container_auto margin_bottom_extra">
                <div></div>
                <div className="text_right">
                  <button
                    className="button_pill button_white outline_black"
                    onClick={this.on_click_step.bind(this, 1)}
                  >
                    <span className="margin_right margin_left">
                      Edit formula
                    </span>
                    <span className="badge_circle background_black">
                      <ICON_ARROW_RIGHT_LINE fill="#fff" />
                    </span>
                  </button>
                </div>
              </section>
            </div>
          )}
        </div>

        <section className="section_2">
          {step_formula === 1 ? (
            <div>
              <section>
                {array_group_insight.map((parent) => {
                  const child_array = array_formula_insight.filter(
                    (child) => child.group === parent.key
                  );
                  return (
                    <article key={parent.key} className="margin_bottom_extra">
                      <span className="button_small background_black margin_bottom">
                        {parent.key}
                      </span>
                      <div>
                        {child_array.map((child) => {
                          const { is_directly_proportional, weight_score } =
                            child;
                          return (
                            <article key={child.key}>
                              <span className="badge background_grey_light outline_grey margin_bottom">
                                <section
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "20px 30px auto",
                                    gridGap: "10px",
                                    alignItems: "center",
                                  }}
                                >
                                  <button
                                    className={`badge_circle ${
                                      is_directly_proportional
                                        ? "background_green"
                                        : "background_red"
                                    }`}
                                    onClick={this.toggle_is_directly_proportional.bind(
                                      this,
                                      child.key
                                    )}
                                  >
                                    {is_directly_proportional ? (
                                      <ICON_ARROW_UP line_color="#fff" />
                                    ) : (
                                      <ICON_ARROW_DOWN line_color="#fff" />
                                    )}
                                  </button>
                                  <button
                                    className="button_small button_white outline_grey"
                                    id={child.key}
                                    onClick={this.toggle_edit_weight}
                                  >
                                    {weight_score}
                                  </button>
                                  <p className="text_small text_left">
                                    {child.key}
                                  </p>
                                </section>
                              </span>
                            </article>
                          );
                        })}
                      </div>
                    </article>
                  );
                })}
              </section>
            </div>
          ) : (
            <div>
              <INPUT_DEMOGRAPHY />
            </div>
          )}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  insight_reducer: state.insight_reducer,
});

export default connect(mapStateToProps, { set_value_insight, insight_update })(
  INPUT_FORMULA_SHORT
);
