import axios from "axios";
import his from "./history";
import { geoServerBaseUrl, iotServerBaseUrl, uploadServerUrl } from "./baseUrl";
import sort_array from "./../../App/validation/sort_array";
import { calculateWithoutConvert } from "../validation/convert_data";
import get_relasi_kai from "../validation/get_relasi_kai";
import get_hour_kai from "../validation/get_hour_kai";
import get_year_kai from "../validation/get_year_kai";
import get_biop_kai from "../validation/get_biop_kai";
import get_year_kai_pendapatan_non_farebox from "../validation/get_year_kai_pendapatan_non_farebox";
import get_kai_net_cash_flow from "../validation/get_kai_net_cash_flow";
import { push_project } from "./folderProjectAction";
import { getGroup } from "./groupActions";

const SERVER_URL = geoServerBaseUrl;
const IOT_SERVER = iotServerBaseUrl;
const UPLOAD_URL = uploadServerUrl;
const cilicis_first_load = 10;

// const bun_server = "http://localhost:4004";
const bun_server = "https://geoserver.mapid.io";

export const status_layer_update = () => {
  return {
    type: "status_layer_update",
  };
};

export const delete_layers_bulk = (body) => async (dispatch) => {
  //delete_layers_bulk
  /*
  body: 
  {
    "geo_layer_attached_ids": Array,
    "geo_layer_ids": Array,
    "geo_project_id"
}
  */
  try {
    dispatch(setLoading("delete_layers_bulk"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    const res = await axios.post(
      SERVER_URL + "/layers_new/delete_layer_bulk",
      body,
      config
    );

    const layer_id_list = [
      ...body.geo_layer_attached_ids,
      ...body.geo_layer_ids,
    ];

    dispatch({
      type: "delete_layers_bulk",
      payload: layer_id_list,
    });

    dispatch({
      type: "status_action",
    });
    dispatch(clearLoading());

    return res;
  } catch (e) {
    dispatch(clearLoading());
  }
};

export const move_layers_into_any_folder = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("move_layers_into_any_folder"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    const response = await axios.post(
      SERVER_URL + "/layers_new/edit_folder_bulk",
      body,
      config
    );

    dispatch({
      type: "move_layers_into_any_folder",
      payload: response,
    });

    dispatch({
      type: "status_action",
    });
    dispatch(clearLoading());

    return response;
  } catch (e) {
    dispatch(clearLoading());
  }
};

export const delete_data_form = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("delete_data_form"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    await axios.post(SERVER_URL + "/layers/delete_data_form", body, config);

    dispatch({
      type: "delete_data_form",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//edit_data_form
export const edit_data_form = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("edit_data_form"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/layers/edit_data_form", body, config);

    dispatch({
      type: "edit_data_form",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

export const delete_comparison_data = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("delete_comparison_data"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    const res = await axios.post(
      SERVER_URL + "/layers/delete_data_pembanding",
      body,
      config
    );

    dispatch({
      type: "delete_comparison_data",
      payload: body,
    });
    dispatch({
      type: "SET_MESSAGE",
      payload: res.data,
    });
    dispatch({
      type: "status_action",
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

// edit data pembanding
export const select_comparison_data = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("select_comparison_data"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/layers/edit_data_pembanding",
      body,
      config
    );

    dispatch({
      type: "select_comparison_data",
      payload: body,
    });
    dispatch({
      type: "SET_MESSAGE",
      payload: res.data,
    });
    dispatch({
      type: "status_action",
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

export const push_revision_list = (body) => async (dispatch) => {
  //push_revision_list
  /*
    const body = {
      geo_layer_id: layer_id,
      feature_index,
      revision_list,
    };
*/
  try {
    dispatch(setLoading("push_revision_list"));
    await axios.post(SERVER_URL + "/layers/push_revision_list", body);
    dispatch({
      type: "push_revision_list",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//push_revision_list_all
export const push_revision_list_all = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("push_revision_list_all"));
    await axios.post(SERVER_URL + "/layers/push_revision_list_all", body);
    dispatch({
      type: "push_revision_list_all",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

/*NON-API*/

export const clearLayer = () => {
  return {
    type: "CLEAR_LAYER",
  };
};

export const clearFormList = () => {
  return {
    type: "CLEAR_FORM_LIST",
  };
};

export const setLoading = (itemLoading) => {
  return {
    type: "SET_LOADING_PROCESS_LAYER",
    payload: itemLoading,
  };
};

export const clearLoading = () => {
  return {
    type: "CLEAR_LOADING_LAYER",
  };
};

// export const setGeoProcessing_mode = (mode) => {
//   return {
//     type: "SET_GEOPROCESSING_MODE",
//     payload: mode,
//   };
// };

export const setGeoProcessingMode = (mode) => (dispatch) => {
  dispatch({
    type: "SET_GEOPROCESSING_MODE",
    payload: mode,
  });
};

export const tableFull = (status) => {
  return {
    type: "TABLE_FULL",
    payload: status,
  };
};

export const setGenanganId = (layer_id) => {
  return {
    type: "SET_GENANGAN_ID",
    payload: layer_id,
  };
};

export const setGenanganCol = (genangan_col_key) => {
  return {
    type: "SET_GENANGAN_COL",
    payload: genangan_col_key,
  };
};

export const setGenanganUnit = (genangan_unit) => {
  return {
    type: "SET_GENANGAN_UNIT",
    payload: genangan_unit,
  };
};

export const finishGenangan = (genangan_col_key) => {
  return {
    type: "FINISH_GENANGAN",
    payload: genangan_col_key,
  };
};

export const filterGenanganByDate = (body) => {
  return {
    type: "FILTER_GENANGAN",
    payload: body,
  };
};

export const filterGenanganByUnix = (body) => {
  return {
    type: "FILTER_GENANGAN_UNIX",
    payload: body,
  };
};

export const resetGenangan = (body) => {
  return {
    type: "RESET_GENANGAN",
    payload: body,
  };
};

export const removeDuplicateFeatures = (body) => {
  return {
    type: "REMOVE_DUPLICATE_FEATURES",
    payload: body,
  };
};

//View data
export const viewData = (view_data_status) => async (dispatch) => {
  dispatch({
    type: "VIEW_DATA_STATUS",
    payload: view_data_status,
  });
};

export const setPOIViewStatus = (item) => {
  return {
    type: "SET_POI_STATUS",
    payload: item,
  };
};

export const setPOIFilter = (item) => {
  return {
    type: "SET_POI_FILTER",
    payload: item,
  };
};

export const setMapMode = (val) => {
  return {
    type: "SET_MAP_MODE",
    payload: val,
  };
};

export const push_poi_active = (mode) => {
  return {
    type: "push_poi_active",
    payload: mode,
  };
};

export const pull_poi_active = (mode) => {
  return {
    type: "pull_poi_active",
    payload: mode,
  };
};

export const all_poi_active = () => {
  return {
    type: "all_poi_active",
  };
};

export const all_poi_not_active = () => {
  return {
    type: "all_poi_not_active",
  };
};

/*GENANGAN START*/

//Get Genangan ketika titik sudah diload

/*GENANGAN FINISH*/

/*POSTY*/

//Create layer
/*
body = {
  name,
  description,
  type,
  geo_project_id,
  folder,
  geojson,
  fields,
  properties,
  domain,
  isGlobalStyle,
  valueStyleList,
  valueStyleProps,
  globalStyleMode,
  status,
}
 */
export const createLayer = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("createLayer"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    let final_body = { ...body, status: "digitasi" };
    const res = await axios.post(
      SERVER_URL + "/layers_new/create",
      final_body,
      config
    );
    let geo_layer = res.data;
    geo_layer.viewStatus = true;
    geo_layer.flyStatus = true;
    dispatch(clearLoading());

    if (body.isForm) {
      his.push(`/form_editor/${geo_layer._id}`);
      dispatch({
        type: "CREATE_FORM",
        payload: geo_layer,
      });
    } else {
      dispatch({
        type: "CREATE_LAYER",
        payload: geo_layer,
      });
    }

    if (body.is_show_and_fly) {
      const geo_layer_id = geo_layer?._id;
      const geo_project_id = body?.geo_project_id;
      const body_get = { geo_layer_id, geo_project_id };
      dispatch(getDetailLayerById(body_get));
    }
    dispatch({
      type: "status_action",
    });

    dispatch({
      type: "set_value_layer",
      payload: {
        key: "current_progress_create_layer",
        value: 100,
      },
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

export const push_features = (body, loading_props) => async (dispatch) => {
  try {
    if (loading_props) {
      const { progressPercent, index, total_length, title } =
        loading_props || {};
      dispatch(
        setLoading(
          `Progress ${title} ${progressPercent}% ${index}/${total_length}`
        )
      );
    } else {
      dispatch(setLoading("push_features"));
    }
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    const res = await axios.post(
      SERVER_URL + "/layers_new/add_multiple_feature",
      body,
      config
    );

    dispatch(clearLoading());

    if (res.status === 200) {
      dispatch({
        type: "status_action",
      });
    }
    return res;
  } catch (e) {
    dispatch(clearLoading());
  }
};

//create layer new tanpa save ke database u/ tracking
export const createLayerNewTracking = (params) => async (dispatch) => {
  try {
    const geo_layer = {
      geojson: { type: "FeatureCollection", features: [] },
      formStatus: { status: "not_propose" },
      formPayment: { provinces: [], cities: [], surveyors: [] },
      genangan: {},
      date: "2021-10-06T09:22:14.837Z",
      date_created: "2021-10-06T09:22:14.837Z",
      picture_url: "",
      isExpand: true,
      isPublic: false,
      isFormPublic: false,
      isGlobalStyle: false,
      isLocationChangeable: false,
      isMonitor: false,
      valuesHide: [],
      properties: [
        {
          key: "icon_image",
          name: "icon_image",
          type: "icon",
          array_templates: [],
          isHighlight: false,
          date: 1633510173236,
          isHide: true,
          isStyle: true,
          defaultValue: "museum",
        },
        {
          key: "text_field",
          name: "label",
          type: "text",
          array_templates: [],
          isHighlight: false,
          date: 1633510173236,
          isHide: true,
          isStyle: true,
          defaultValue: "point",
        },
        {
          key: "fill_color",
          name: "fill_color",
          type: "color",
          array_templates: [],
          isHighlight: false,
          date: 1633510173236,
          isHide: true,
          isStyle: true,
          defaultValue: "#1E5E96",
        },
        {
          key: "circle_radius",
          name: "circle_radius",
          type: "number",
          array_templates: [],
          isHighlight: false,
          date: 1633510173236,
          isHide: true,
          isStyle: true,
          defaultValue: 5,
        },
        {
          key: "circle_stroke_width",
          name: "circle_stroke_width",
          type: "number",
          array_templates: [],
          isHighlight: false,
          date: 1633510173236,
          isHide: true,
          isStyle: true,
          defaultValue: 1,
        },
        {
          key: "circle_stroke_color",
          name: "circle_stroke_color",
          type: "color",
          array_templates: [],
          isHighlight: false,
          date: 1633510173236,
          isHide: true,
          isStyle: true,
          defaultValue: "#ffffff",
        },
        {
          key: "is_using_icon",
          name: "Use icon",
          type: "checkbox",
          array_templates: [],
          isHighlight: false,
          date: 1633510173236,
          isHide: true,
          isStyle: true,
          defaultValue: false,
        },
        {
          key: "is_using_label",
          name: "Use label",
          type: "checkbox",
          array_templates: [],
          isHighlight: false,
          date: 1633510173236,
          isHide: true,
          isStyle: true,
          defaultValue: false,
        },
      ],
      status: "digitasi",
      storage_key: "",
      valueStyleList: [],
      _id: "tracking",
      name: "Tracking",
      description: "",
      user: {
        profile_picture: {
          url: "https://s3.us-west-2.amazonaws.com/machrus/profile_picture/egipratama/egipratama_1586494376306.jpg",
          name: "egipratama_1586494376306.jpg",
          url_compressed:
            "https://s3.us-west-2.amazonaws.com/machrus/profile_picture/egipratama/egipratama_1586494376306_compressed.jpg",
          name_compressed: "egipratama_1586494376306_compressed.jpg",
          isAvatar: false,
          avatar: 1,
        },
        _id: "5dc3939ce5e8052f7526f944",
        full_name: "Egi Pratama",
        alias_name: "egi",
        badge: "",
        name: "egipratama",
      },
      geo_project: "611eafa6be8a2635e15c4afc",
      type: "Point",
      fields: [],
      domain_list: [
        { _id: "615d6ac6b0d8430e2da3fa74", domain: "geo.mapid.io" },
      ],
      picture_url_square: [],
      geo_project_attach: [],
      comments: [],
      __v: 0,
      folder: "",
      link: "615d6ac6b0d8430e2da3fa73",
      layer: "615d6ac6b0d8430e2da3fa73",
      viewStatus: true,
    };

    dispatch({
      type: "CREATE_LAYER",
      payload: geo_layer,
    });
    dispatch({
      type: "status_action",
    });
  } catch (error) {
    dispatch(clearLoading());
  }
};

export const addGeometryTracking = (params) => async (dispatch) => {
  const payload = {
    geo_layer_id: "tracking",
    feature: {
      key: params.trackId,
      type: "Feature",
      properties: {
        icon_image: "museum",
        text_field: "icon_image",
        fill_color: "#1E5E96",
        circle_radius: 5,
        circle_stroke_width: 1,
        circle_stroke_color: "#fff",
      },
      geometry: {
        coordinates: [params.long, params.lat],
        type: "Point",
      },
      isHide: false,
    },
  };
  dispatch({
    type: "PUSH_FEATURE",
    payload: payload,
  });
};

export const editGeometryTracking = (params) => async (dispatch) => {
  const payload = {
    feature_key: params.trackId,
    geo_layer_id: params.featureId,
    geometry: {
      coordinates: [[params.long, params.lat]],
      type: "MultiPoint",
    },
  };
  dispatch({
    type: "EDIT_GEOMETRY",
    payload: payload,
  });
};

export const editLastGeometryTracking = (params) => async (dispatch) => {
  const { geo_layer } = params;
  const { geojson } = geo_layer;

  if (geojson) {
    const coordinates = geojson.features[0].geometry.coordinates;
    coordinates[coordinates.length - 1] = [params.long, params.lat];

    // const payload = {
    //   feature_key: geojson.features[0].key,
    //   geo_layer_id: params.trackId,
    //   geometry: {
    //     coordinates: coordinates,
    //     type: "LineString",
    //   },
    // };
  }
};

export const deleteDriverTracking =
  (featureKey, geo_layer_id, geo_project_id) => async (dispatch) => {
    try {
      dispatch(setLoading("get_detail"));

      const res = await axios.post(
        SERVER_URL + "/layers/tracking/" + featureKey,
        {
          status: "off",
          keterangan_berhenti: "Di berhentikan admin",
        }
      );

      if (res.status === 200) {
        const config = {
          headers: {
            accesstoken: localStorage.token_mapid,
          },
        };

        const res = await axios.get(
          SERVER_URL +
            `/layers_new/get_detail_layer_by_id/${geo_layer_id}/${geo_project_id}`,
          config
        );
        dispatch(clearLoading());
        let geo_layer = res.data;
        let features = geo_layer.geojson.features;
        geo_layer.filtered_features = features;
        geo_layer.viewStatus = true;
        const payload = {
          geo_layer_item: geo_layer,
          geo_layer_id: geo_layer_id,
          geo_project_id: geo_project_id,
        };
        dispatch({
          type: "GET_LAYER_DETAIL",
          payload: payload,
        });
        dispatch({
          type: "status_action",
        });
      } else {
        dispatch(clearLoading());
      }
    } catch (e) {
      dispatch(clearLoading());
    }
  };

export const getRoutesTracking = (params) => {
  return new Promise((resolve) => {
    const jsonParam = {
      coordinates: [
        [params.lastLng, params.lastLat],
        [params.newLng, params.newLat],
      ],
      simplified: false,
      user_id: "karlo_indonesia",
      golongan: 4,
    };
    axios
      .post(`${IOT_SERVER}/routing/direction/simple`, jsonParam)
      .then((r) => resolve(r.data))
      .catch((e) => {});
  });
};

//Edit Genangan
export const editGenangan = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("edit_genangan"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/layers_new/edit_genangan", body, config);
    dispatch(clearLoading());
    dispatch({
      type: "EDIT_GENANGAN",
      payload: body,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//Edit Layer General (name, pindah folder)
export const editLayerGeneral = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("edit_general"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/layers_new/edit_general", body, config);
    dispatch(clearLoading());
    dispatch({
      type: "EDIT_LAYER_GENERAL",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//Edit Layer General (name, pindah folder) layer attached
export const editLayerGeneralAttached = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("edit_general_attached"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(
      SERVER_URL + "/layers_new/edit_general_attached",
      body,
      config
    );
    dispatch(clearLoading());
    dispatch({
      type: "EDIT_LAYER_GENERAL_ATTACHED",
      payload: body,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//Push layer attach to other project
export const attachLayer = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("push_project_attach"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const geo_project_id = body.geo_project_id;
    let geo_layer = { ...body.geo_layer };
    geo_layer.status = "attached";
    const geo_layer_id = geo_layer._id;
    const body_final = {
      geo_layer_id,
      geo_project_id,
    };
    await axios.post(
      SERVER_URL + "/layers_new/push_project_attach",
      body_final,
      config
    );
    dispatch(clearLoading());
    if (geo_layer.name) {
      dispatch({
        type: "PUSH_PROJECT_ATTACH",
        payload: geo_layer,
      });
    }
  } catch (e) {
    dispatch(clearLoading());
  }
};

//Delete layer attach to other project
export const deleteLayerAttach = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("delete_layer_attach"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(
      SERVER_URL + "/layers_new/delete_layer_attach",
      body,
      config
    );
    dispatch(clearLoading());
    dispatch({
      type: "DELETE_LAYER",
      payload: body.geo_layer_id,
    });

    dispatch({
      type: "status_action",
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//Delete Layer
export const deleteLayer = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("delete_layer"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/layers_new/delete_layer", body, config);
    dispatch(clearLoading());
    dispatch({
      type: "DELETE_LAYER",
      payload: body.geo_layer_id,
    });

    dispatch({
      type: "status_action",
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

export const importLayer =
  (body, is_not_fly_to_batch_geojson = false) =>
  async (dispatch) => {
    let error_message;
    let layer_id = null;
    try {
      dispatch({
        type: "set_value_properties",
        payload: {
          key: "save_layer_progress",
          value: 0,
        },
      });
      dispatch(setLoading("importLayer"));
      const config = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };

      //khusus_danamas
      const domain = window.location.hostname;
      let architecture;
      if (domain === "danamas.mapid.io") {
        architecture = "v1";
      } else {
        architecture = process.env.REACT_APP_ARCHITECTURE;
      }

      // const body_create = {
      //   name,
      //   description,
      //   type,
      //   folder,
      //   user_id,
      //   geo_project_id,
      //   fields,
      //   properties,
      //   geojson: geojson,
      //   status: status ? status : "upload",
      //   architecture: architecture, // penambahan versi untuk arcitekture data v2

      //   //atribut styling
      //   isGlobalStyle,
      //   valueStyleList,
      //   globalStyleMode,
      //   valueStyleProps,
      //   is_show_and_fly,
      //   insight_object,
      // };

      const geojson = body?.geojson || {};
      const features = body?.geojson?.features || [];

      const body_create = {
        ...body,
        status: body?.status || "upload",
        architecture: architecture,
      };

      // upload untuk versi architecture v2
      if (body_create?.architecture === "v2") {
        body_create.geojson.features = [];
      }

      const res = await axios.post(
        UPLOAD_URL + "/layers_new/create",
        body_create,
        config
      );
      layer_id = res.data._id;

      // upload untuk versi architecture v2
      if (res.data?.architecture === "v2") {
        let step = 100;

        for (let index = 0; index < features.length; index += step) {
          const progressPercent = parseInt((index / features.length) * step);
          dispatch(
            setLoading(
              `Progress ${progressPercent}% ${index}/${features.length}`
            )
          );
          const element = features.slice(index, index + step);

          const body = element.map((e) => {
            return { geo_layer_id: layer_id, feature: e };
          });
          error_message = await axios.post(
            SERVER_URL + "/layers/v2/push_feature_bulk",
            body,
            config
          );
          await delay(0);
        }
        body_create.geojson.features = features;
      }

      let geo_layer = res.data;
      geo_layer.geojson_filtered = geo_layer.geojson;

      let body_upload = {
        geo_layer_id: geo_layer._id,
        geojson: geojson,
        ...body_create,
      };

      dispatch({
        type: "CREATE_GEOJSON_LAYER",
        payload: {
          ...geo_layer,
          // ...body_create
        },
        is_not_fly_to_batch_geojson: is_not_fly_to_batch_geojson,
      });

      if (!is_not_fly_to_batch_geojson) {
        dispatch({
          type: "REPLACE_GEOJSON",
          payload: body_upload,
          is_not_fly_to_batch_geojson: is_not_fly_to_batch_geojson,
        });
      }

      dispatch({
        type: "status_action",
      });

      if (!is_not_fly_to_batch_geojson) {
        dispatch({
          type: "fly_action",
        });
      }

      if (body.is_show_and_fly) {
        const geo_layer_id = geo_layer?._id;
        const geo_project_id = body?.geo_project_id;
        const body_get = { geo_layer_id, geo_project_id };
        dispatch(getDetailLayerById(body_get));
      }

      dispatch({
        type: "set_value_properties",
        payload: {
          key: "save_layer_progress",
          value: 100,
        },
      });

      dispatch(clearLoading());
      return {
        isSuccess: true,
      };
    } catch (error) {
      dispatch(clearLoading());
      error_message = error?.response?.data?.error;
      return {
        layer_id,
        isSuccess: false,
        error: error_message || error.message,
      };
    }
  };

//fly_batch
export const fly_batch = () => async (dispatch) => {
  dispatch({
    type: "fly_action",
  });
};

//Create Project with/without _id group
export const createProjectNew = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("create_project"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/layers_new/create_project",
      body,
      config
    );
    const project_id = res?.data?._id;
    const folder_id_selected = body?.folder_id_selected;
    if (project_id && folder_id_selected) {
      //lakukan push project_id ke dalam folder
      const body_push = {
        folder_id: folder_id_selected,
        project_id: project_id,
        key: "geo_project_list",
      };
      dispatch(push_project(body_push)); //push project to db folder
    }

    const key = body.key;
    dispatch({
      type: "push_value_project_to_start",
      payload: {
        key: key,
        value: res.data,
      },
    });

    dispatch(clearLoading());
    if (res?.status === 200) {
      return res;
    }
  } catch (e) {
    dispatch(clearLoading());
  }
};

/*GET METHODE*/

//Get layer only
export const getLayerOnly = (link) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage?.token_mapid,
      },
    };
    dispatch(setLoading("get_list_by_link_project"));
    const res = await axios.get(
      SERVER_URL + `/layers_new/get_layer_only/${link}`,
      config
    );
    let layer = {
      ...res.data,
      viewStatus: true,
      filtered_features: res.data?.geojson?.features
        ? res.data?.geojson?.features
        : [],
    };
    dispatch({
      type: "GET_LAYER_ONLY",
      payload: layer,
    });
    dispatch({
      type: "status_action",
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

/** get layer detail by id without reducer */
export const get_features_by_layer_id_without_reducer =
  (body) => async (dispatch) => {
    try {
      const { project_id, geo_layer_id, architecture } = body;

      const config = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };

      let features = [];
      if (architecture === "v2") {
        const res_total_row = await axios.get(
          bun_server +
            `/moneys_bun/v2/get_layer_count_by_id/${geo_layer_id}/{project_id}`,
          config
        );

        if (res_total_row?.status === 200) {
          const features_length = res_total_row?.data;

          let condition = true;
          let skip = 0,
            limit = 100;

          const stop = 10_000;

          while (condition) {
            const progressPercent = parseInt((skip / features_length) * 100);
            dispatch(
              setLoading(
                `Loading ${progressPercent}% ${skip}/${features_length}`
              )
            );

            const res = await axios.get(
              bun_server +
                `/moneys_bun/v2/get_layer_detail_by_id/${geo_layer_id}/${project_id}?skip=${skip}&limit=${limit}`,
              config
            );

            if (res.data.length === 0 || skip >= stop) {
              condition = false;
            } else {
              features.push(...res.data);
              if (res.data.length !== limit) {
                condition = false;
              }
            }
            skip += limit;
            await delay(150);
          }
        }
      } else {
        const res = await axios.get(
          bun_server +
            `/moneys_bun/get_layer_detail_by_id/${geo_layer_id}/${project_id}`,
          config
        );

        if (res?.status === 200) {
          features = res.data?.geojson?.features;
        }
      }

      dispatch(clearLoading());

      return features;
    } catch (error) {
      dispatch(clearLoading());
    }
  };

// Get Layer List By link Project
export const getListLayerByLinkNew = (link) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(setLoading("get_list_by_link_project"));
    const res = await axios.get(
      SERVER_URL + `/layers_new/list_layer/${link}`,
      config
    );

    dispatch({
      type: "GET_LAYER_LIST",
      payload: res.data,
    });

    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//Get Layer By id layer
export const getDetailLayerById = (content) => async (dispatch) => {
  try {
    const {
      geo_project_id,
      geo_layer_id,
      is_ignore_set_layer_id,
      folder_id,
      capex_value,
      geo_layer_item,
      skipLoading,
      tableStatus,
      geometryStatus,
      flyStatus,
    } = content;
    const { is_custom_endpoint } = geo_layer_item || {};

    if (geo_layer_id) {
      const config = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };
      if (!skipLoading) {
        dispatch(setLoading("get_detail"));
      }
      const res = await axios.get(
        bun_server +
          `/moneys_bun/get_layer_detail_by_id/${geo_layer_id}/${geo_project_id}`,
        config
      );
      let geo_layer = res.data || {};

      //pengecekan apakah ada data pembanding atau tidak, jika ada akan dilakukan get data pembanding
      const id_pembanding = geo_layer?.layer_data_list?.[0]?._id;
      let layer_pembanding = {};
      if (id_pembanding) {
        const features_appraisal = geo_layer?.geojson?.features || [];
        const geo_project_id = geo_layer?.layer_data_list?.[0]?.geo_project;
        const res = await axios.get(
          bun_server +
            `/moneys_bun/get_layer_detail_by_id/${id_pembanding}/${geo_project_id}?layer_parent=${geo_layer_id}`,
          config
        );
        layer_pembanding = res?.data || {};
        if (layer_pembanding?._id) {
          const geo_layer_id = layer_pembanding?._id;
          //generate jarak ke apraisal
          let geojson_pembanding = layer_pembanding.geojson;
          let features_pembanding = geojson_pembanding?.features || [];
          const fields = layer_pembanding?.fields || [];
          features_pembanding = features_pembanding.map((feature) => {
            const feature_appraisal = features_appraisal.find((item) =>
              item.data_pembanding_list.includes(feature.key)
            );
            let features = [feature];
            if (feature_appraisal) {
              features = calculateWithoutConvert({
                features,
                fields,
                feature_appraisal,
              });
            }
            return { ...features[0], type: "Feature" };
          });
          geojson_pembanding.features = features_pembanding;
          const payload = { geo_layer_id, geojson: geojson_pembanding };
          dispatch({
            type: "REPLACE_GEOJSON",
            payload,
          });
        }
      }

      //pengecekan apakah ada field counting_custom
      const fields = res?.data?.fields || [];
      const fields_cc = fields.filter(
        (field) => field?.type === "counting_custom"
      );

      fields_cc.forEach((field) => {
        if (field?.custom_counting_id) {
          const body = {
            geo_project_id,
            geo_layer_id: field?.custom_counting_id,
            is_ignore_set_layer_id: true,
          };
          dispatch(getDetailLayerById(body));
        }
      });

      let geojson = geo_layer?.geojson || {};
      // PROSES READ FEATURE UNTUK ARCHITECTURE BARU V2
      if (res.data?.architecture === "v2") {
        geojson.features = [];
        // CUSTOM API
        if (is_custom_endpoint) {
          const config = {
            headers: {
              accesstoken: localStorage.token_mapid,
            },
          };
          const res = await axios.get(
            SERVER_URL +
              "/open_api/get_detail_layer_custom_endpoint/" +
              geo_layer_id,
            config
          );
          geo_layer.fields = res.data?.fields;
          geojson.features.push(...res.data?.geojson?.features);
        }
        // DEFAULT V2
        else {
          const count = await axios.get(
            bun_server +
              `/moneys_bun/v2/get_layer_count_by_id/${geo_layer_id}/${geo_project_id}`,
            config
          );
          const features_length = count.data;
          let condition = true;
          let skip = 0,
            limit = 100;
          const stop = 10_000;
          while (condition) {
            const progressPercent = parseInt((skip / features_length) * 100);
            dispatch(
              setLoading(
                `Loading ${progressPercent}% ${skip}/${features_length}`
              )
            );
            const res = await axios.get(
              bun_server +
                `/moneys_bun/v2/get_layer_detail_by_id/${geo_layer_id}/${geo_project_id}?skip=${skip}&limit=${limit}`,
              config
            );
            if (res.data.length === 0 || skip >= stop) {
              condition = false;
            } else {
              geojson.features.push(...res.data);
              if (res.data.length !== limit) {
                condition = false;
              }
            }
            skip += limit;
            await delay(150);
          }
        }
      }

      if (geo_layer?._id) {
        const fields = geo_layer?.fields || [];
        let features = geojson?.features || [];

        features = features.map((feature) => {
          let properties = feature?.properties || {};
          properties = { ...properties, key: feature.key };
          feature.properties = properties;
          return feature;
        });

        const features_after_child_calc = features.map((feature_parent) => {
          let child_array = feature_parent?.child_array || [];

          if (child_array.length > 0) {
            child_array = child_array.map((item) => {
              const fields_child = fields.filter(
                (field) => field.parent_uuid === item.parent_uuid
              );
              const result = calculateWithoutConvert({
                features: [item],
                fields: fields_child,
                feature_parent,
                source: "fetch_data_first_child",
              });
              return result[0];
            });
          }
          feature_parent.child_array = child_array;

          return feature_parent;
        });
        const features_after_parent_calc = calculateWithoutConvert({
          features: features_after_child_calc,
          fields,
          source: "fetch_data_first",
          capex_value,
        });
        const features_final = features_after_parent_calc.map(
          (feature, idx) => {
            return {
              ...feature,
              idx,
              type: "Feature",
            };
          }
        );
        geojson.features = features_final;

        //generate chart untuk beberapa type data timeseries dari KAI
        //CASE 1
        if (geo_layer?.timeseries_object?.timeseries_mode === "kai") {
          //masukkan value ke dalam geo_layer
          const relasi_list = get_relasi_kai(geo_layer);
          const hour_list = get_hour_kai(geo_layer);
          geo_layer.relasi_list = relasi_list?.relasi_array_of_object;
          geo_layer.max_penumpang = relasi_list?.max_penumpang;
          geo_layer.hour_list = hour_list;
          //masukkan value max_penumpang ke dalam folder_id
          if (folder_id) {
            dispatch({
              type: "set_folder_timeseries_kai",
              payload: {
                geo_layer_id: geo_layer_id,
                folder_id,
                max_penumpang: relasi_list.max_penumpang,
                max_passenger: relasi_list.max_passenger,
                max_train: relasi_list.max_train,
              },
            });
          }
        }
        //CASE 2
        else if (
          geo_layer?.timeseries_object?.timeseries_mode === "kai_income_farebox"
        ) {
          const relasi_list = get_relasi_kai(geo_layer);
          geo_layer.relasi_list = relasi_list?.relasi_array_of_object;
        }
        //CASE 3
        else if (geo_layer?.timeseries_object?.timeseries_mode === "kai_biop") {
          const chart_js_data_biop = get_biop_kai(geo_layer);
          geo_layer.chart_js_data_biop = chart_js_data_biop;
        }
        //CASE 4
        else if (
          geo_layer?.timeseries_object?.timeseries_mode ===
          "kai_komponen_pendapatan_tiket"
        ) {
          const timeseries_komponen_array = get_year_kai(geo_layer);
          geo_layer.timeseries_komponen_array = timeseries_komponen_array;
        }
        //CASE 5
        else if (
          geo_layer?.timeseries_object?.timeseries_mode ===
          "kai_pendapatan_non_farebox"
        ) {
          const timeseries_komponen_array =
            get_year_kai_pendapatan_non_farebox(geo_layer);
          geo_layer.timeseries_komponen_array = timeseries_komponen_array;
        } //CASE 6
        else if (
          geo_layer?.timeseries_object?.timeseries_mode === "kai_net_cash_flow"
        ) {
          const chart_js_data_net_cash_flow = get_kai_net_cash_flow(geo_layer);
          geo_layer.chart_js_data_net_cash_flow = chart_js_data_net_cash_flow;
        }
        //khusus untuk layer banjir, hanya tampilkan 7 data terbaru
        if (geo_layer.type_2 === "banjir") {
          const geojson = geo_layer.geojson;
          geo_layer.geojson_filtered = { ...geojson };
          geo_layer.geojson_filtered.features = features_final
            .slice(features_final.length - cilicis_first_load)
            .reverse();
          const payload = {
            geo_layer_item: geo_layer,
            content,
          };
          dispatch({
            type: "GET_LAYER_DETAIL",
            payload: payload,
          });
        } else {
          geo_layer.geojson_filtered = { ...geojson };

          const features_sorted = sort_array(features_final, "idx", true);
          geo_layer.geojson_filtered.features = features_sorted;
          const payload = {
            geo_layer_item: geo_layer,
            content,
            is_ignore_set_layer_id,
          };
          dispatch({
            type: "GET_LAYER_DETAIL",
            payload: payload,
          });
          if (!is_ignore_set_layer_id) {
            dispatch({
              type: "fly_action",
            });
          }
        }
        if (tableStatus !== undefined) {
          dispatch({
            type: "SET_TABEL_STATUS",
            payload: tableStatus,
          });
        } else if (geometryStatus !== undefined) {
          dispatch({
            type: "SET_GEOMETRY_STATUS",
            payload: geometryStatus,
          });
        } else if (flyStatus !== undefined) {
          dispatch({
            type: "SET_FLY_STATUS",
            payload: flyStatus,
          });
        }
        dispatch(clearLoading());
        dispatch({
          type: "fly_action",
        });
      }

      dispatch({
        type: "status_action",
      });
    }
  } catch (error) {
    dispatch(clearLoading());
  }
};

//Get All Layer Type Point By User for FORM LIST
const defaultParams = { limit: 3, skip: 0, append: false };
export const getListLayerTypePoint =
  (params = defaultParams) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };
      dispatch(setLoading("get_layer_point"));
      dispatch({ type: "SET_LOAD_MORE_LOADING", payload: true });
      const limit = params.limit ? params.limit : 0;
      const skip = params.skip ? params.skip : 0;
      const res = await axios.get(
        SERVER_URL + `/layers_new/get_layer_point?limit=${limit}&skip=${skip}`,
        config
      );
      // dispatch({
      //   type: "GET_FORM_LIST",
      //   payload: res.data,
      // });
      if (params.append) {
        let lastProject = params.lastProject;
        let newListProject = [...lastProject, ...res.data];
        dispatch({
          type: "GET_FORM_LIST",
          payload: newListProject,
        });
        if (res.data.length === 0) {
          dispatch({
            type: "SET_LOAD_MORE_BTN",
            payload: false,
          });
        }
      } else {
        dispatch({
          type: "GET_FORM_LIST",
          payload: res.data,
        });
        dispatch({
          type: "SET_LOAD_MORE_BTN",
          payload: true,
        });
      }
      dispatch(clearLoading());
      dispatch({ type: "SET_LOAD_MORE_LOADING", payload: false });
    } catch (error) {
      dispatch(clearLoading());
    }
  };

//Get layer only paid form
export const getListPaidForm = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(setLoading("get_paid_form"));
    const res = await axios.get(
      SERVER_URL + `/layers_new/get_paid_form`,
      config
    );
    dispatch({
      type: "GET_PAID_FORM_LIST",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//Get Layer By link layer storage
export const getDetailLayerByIdStorage = (content) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    if (!content?.skipLoading) {
      dispatch(setLoading("get_detail"));
    }
    const res = await axios.get(
      SERVER_URL +
        `/file/download/${content.geo_layer_id}/${content.geo_project_id}`,
      config
    );
    dispatch(clearLoading());
    let geo_layer = res.data;
    let features = geo_layer.geojson.features;
    geo_layer.filtered_features = features;
    geo_layer.viewStatus = true;
    const payload = {
      geo_layer_item: geo_layer,
      content,
    };
    dispatch({
      type: "GET_LAYER_DETAIL",
      payload: payload,
    });
    if (content.isAnalyzer !== undefined) {
      dispatch({
        type: "SET_ANALYZER_INFO_GEOJSON",
        payload: {
          geojson: geo_layer.geojson,
          id: content.geo_layer_id,
          features: geo_layer.geojson.features,
        },
      });
      dispatch({
        type: "SET_SELECTED_INFO_LAYER",
        payload: content.geo_layer_id,
      });
    }
    if (content.tableStatus !== undefined) {
      dispatch({
        type: "SET_TABEL_STATUS",
        payload: content.tableStatus,
      });
    } else if (content.geometryStatus !== undefined) {
      dispatch({
        type: "SET_GEOMETRY_STATUS",
        payload: content.geometryStatus,
      });
    } else if (content.flyStatus !== undefined) {
      dispatch({
        type: "SET_FLY_STATUS",
        payload: content.flyStatus,
      });
    }
    dispatch({
      type: "status_action",
    });
  } catch (error) {
    dispatch(clearLoading());
  }
};

export const setToolboxMode = (mode) => (dispatch) => {
  dispatch({
    type: "SET_TOOLBOX_MODE",
    payload: mode,
  });

  dispatch({
    type: "SET_DRAW_MODE",
    payload: "simple_select",
  });
};

export const clearToolboxFeatures = () => {
  return {
    type: "CLEAR_TOOLBOX_FEATURE",
  };
};

export const toggleViewToolbox = () => {
  return {
    type: "TOGGLE_TOOLBOX_VIEW",
  };
};

export const setToolboxId = (id) => {
  return {
    type: "SET_TOOLBOX_ID",
    payload: id,
  };
};

export const setDrawMode = (mode) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_DRAW_MODE",
      payload: mode,
    });
    dispatch({
      type: "status_action",
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

// export const setDrawMode = (mode) => {
//   return {
//     type: "SET_DRAW_MODE",
//     payload: mode,
//   };
// };

export const setDrawStatus = (status) => {
  return {
    type: "SET_DRAW_STATUS",
    payload: status,
  };
};

export const changeRadius = (id, radius) => {
  return {
    type: "CHANGE_BUFFER_RADIUS",
    payload: {
      id,
      radius,
    },
  };
};

export const setStatisticMode = (mode) => {
  return {
    type: "SET_STATISTIC_MODE",
    payload: mode,
  };
};

export const setMapViewport = (viewport) => {
  return {
    type: "SET_MAP_VIEWPORT",
    payload: viewport,
  };
};

//
/*
let body = {
        origin,
        email,
        group_id,
        layer_id,
        feature_key,
      };
*/
export const invitations = (body) => async (dispatch) => {
  try {
    await axios.post(SERVER_URL + "/mail/invitations", body);
    const group_id = body.group_id;
    await dispatch(getGroup(group_id));
    dispatch({
      type: "invitations",
      payload: body,
    });
    dispatch({
      type: "status_action",
    });
  } catch (error) {
    const error_object = error?.response?.data;
    dispatch(clearLoading());
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "error_object",
        value: error_object,
      },
    });
  }
};

export const invitations_group = (body) => async (dispatch) => {
  /*
      const body = {
        origin: "danamas.mapid.io",
        layer_id: layer_id,
        key: key,
        user_id_surveyor: data.user._id,
        email_surveyor: data.user.email,
        name_surveyor: data.user.name,
        table_data: obj,
        data_user: data,
      };
*/

  try {
    await axios.post(SERVER_URL + "/layers_new/assign", body);
    dispatch({
      type: "invitations_group",
      payload: body,
    });
    dispatch({
      type: "status_action",
    });
  } catch (error) {
    dispatch(clearLoading());
  }
};

export const reject_feature = (body) => async (dispatch) => {
  /*
body: {
geo_layer_id,
feature_key,
revision_list
}
*/

  try {
    await axios.post(SERVER_URL + "/forms/reject_feature", body);
    dispatch({
      type: "reject_feature",
      payload: body,
    });
    dispatch({
      type: "status_action",
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

export const accept_feature = (body) => async (dispatch) => {
  /*
body: {
geo_layer_id,
feature_key
}
*/

  try {
    await axios.post(SERVER_URL + "/forms/accept_feature", body);
    dispatch({
      type: "accept_feature",
      payload: body,
    });
    dispatch({
      type: "status_action",
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//Set request_id
export const setRequestId = (request_id) => async (dispatch) => {
  dispatch({
    type: "SET_REQUEST_ID",
    payload: request_id,
  });
};

// delete toolbox
export const set_delete_toolbox = (params) => (dispatch) => {
  dispatch({
    type: "REMOVE_TOOLBOX_FEATURE",
    payload: params,
  });
  dispatch({
    type: "status_action",
  });
};

// Apply color via pie chart
export const push_apply_color = (params) => (dispatch) => {
  dispatch({
    type: "push_apply_color",
    payload: params,
  });
  dispatch({
    type: "status_action",
  });
};

// Reset color via pie chart
export const delete_apply_color = (params) => (dispatch) => {
  dispatch({
    type: "delete_apply_color",
    payload: params,
  });
  dispatch({
    type: "status_action",
  });
};

// Migration Layer
export const migration_action = (body) => async (dispatch) => {
  try {
    const { geo_layer_id, geo_project_id } = body;
    dispatch(setLoading("Migration"));
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const axios_res = await axios.get(
      SERVER_URL +
        `/layers_new/get_detail_layer_by_id/${geo_layer_id}/${geo_project_id}`,
      config_header
    );
    const axios_response = axios_res.data;

    const layer_id = axios_response._id;
    const features = axios_response.geojson.features;
    let skip = 100;
    for (let index = 0; index < features.length; index += skip) {
      const progressPercent = parseInt((index / features.length) * 100);
      dispatch(
        setLoading(`Progress ${progressPercent}% ${index}/${features.length}`)
      );
      const element = features.slice(index, index + skip);
      const body = element.map((e) => {
        return { geo_layer_id: layer_id, feature: e };
      });
      await axios.post(
        SERVER_URL + "/layers/v2/push_feature_bulk",
        body,
        config_header
      );
      await delay(300);
    }

    // ubah layer field architecture menjadi v2
    await axios.post(
      SERVER_URL + "/layers_new/edit_architecture",
      {
        geo_layers_id: geo_layer_id,
        geo_project_id: geo_project_id,
        architecture: "v2",
      },
      config_header
    );
    dispatch(setLoading("Migration is done, please refresh page now"));

    setTimeout(() => {
      dispatch(clearLoading());
    }, 10000);
  } catch (error) {
    dispatch(clearLoading());
  }
};

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
