import React, { Component, createRef } from 'react'
// import Modal from "../reuseable/Modal";
import { FileUploader } from 'react-drag-drop-files';
// import readXlsxFile, { readSheetNames } from 'read-excel-file';
import * as ExcelJS from "exceljs"
import * as XLSX from 'xlsx';

import dict from "../../Data/dict.json";
import { get_file } from '../../App/validation/handle_file';

import 'handsontable/dist/handsontable.full.min.css';
import { registerAllModules } from 'handsontable/registry';
import { HotTable } from '@handsontable/react';


/* KEBUTUHAN DEVELOPMENT */
// import READ_ME from "../../data_examples/sheet_1.json";
// import DATA_OBJECT from "../../data_examples/sheet_2.json";
// import UJI_HBU_LEGAL from "../../data_examples/sheet_3.json";
// import DATA from "../../data_examples/sheet_4.json";
// import color_indexes from "../../Data/color_indexes.json"
import { calculateExcelFormula, numberToColumn, columnToNumber, getAllSelectedValue, parseReference } from '../../App/validation/calculateExcelFormula';
import Modal from '../common_modal/Modal';
import { create_sheet, get_sheets_by_cell } from '../../App/actions/sheetAction';
import { connect } from 'react-redux';
import { getGeoLayer } from '../../App/reduxHelper/layer';
import SheetButton from './SheetButton';
import { snackbar } from '../../App/actions/snackbarActions';
import Handsontable from 'handsontable';
import { applyTint } from '../../App/validation/colorHex'

import is_not_empty from "../../App/validation/is_not_empty";

registerAllModules()

class ExcelTable extends Component {
  constructor(props) {
    super(props);
    this.hotTableRef = createRef(null)
    this.state = {
      modal_upload: false,
      uploaded_file: {},
      worksheet: null,
      sheetnames: [],
      filename: "",
      selectedSheet: "",
      selectedCell: null,
      selectedCells: null,
      isSingleSelected: true,
    }
  }

  // // development
  // componentDidMount = async () => {
  //   this.setState({
  //     selectedSheet: "Read Me",
  //     // sheetnames: ["Read Me", "Data Objek", "Uji HBU - Legal", "DATA"]
  //   })
  // }


  toggle_modal = async () => {
    const { modal_upload } = this.state
    const { geo_layer_id, feature_key, field_key } = this.props;
    const body = {
      geo_layer_id,
      feature_key,
      field_key
    }

    if (!modal_upload) {
      const data = await this.props.get_sheets_by_cell(body);
      const sheetnames = data?.map(item => ({ _id: item?._id, ...item?.metadata })).sort(item => item?.order);
      this.setState({
        worksheet: data?.length > 0 ? data : null,
        sheetnames: sheetnames,
        modal_upload: !modal_upload,
        selectedSheet: sheetnames?.[0]?.id_ori || ""
      })
    } else {
      this.setState({
        worksheet: null,
        sheetnames: [],
        selectedSheet: "",
        modal_upload: !modal_upload,
        uploaded_file: {},
        filename: "",
        selectedCell: null
      })
    }
  }

  // generateColumns = (lastColumn) => {
  //   const columns = [];
  //   const startCharCode = 'A'.charCodeAt(0);
  //   const endCharCode = lastColumn?.charCodeAt(0);

  //   for (let i = startCharCode; i <= endCharCode + 5; i++) {
  //     const letter = String.fromCharCode(i);
  //     columns.push({ data: letter, title: letter });
  //   }

  //   return columns;
  // }



  generateColumns = (lastColumn) => {
    const columns = [];
    const startCharCode = 'A'.charCodeAt(0);
    const getColumnName = (index) => {
      let name = '';
      while (index >= 0) {
        name = String.fromCharCode((index % 26) + startCharCode) + name;
        index = Math.floor(index / 26) - 1;
      }
      return name;
    };

    let endIndex = 0;
    if (lastColumn) {
      let charIndex = 0;
      for (let i = lastColumn.length - 1; i >= 0; i--) {
        endIndex += (lastColumn.charCodeAt(i) - startCharCode + 1) * Math.pow(26, charIndex++);
      }
    }

    for (let i = 0; i <= endIndex + 5; i++) {
      const columnName = getColumnName(i);
      columns.push({ data: columnName, title: columnName });
    }

    return columns;
  };

  convertExcelDataToHandsontableFormat = ({ sheet, rawData, selectedSheet }) => {
    const data = [];
    const columnSet = new Set();

    // Parsing each cell from the rawData object
    if (Array.isArray(sheet?.value)) {
      for (const cellData of sheet?.value) {
        if (cellData.p && cellData.p.match(/^[A-Z]+\d+$/)) {  // Check for column-row pattern in cell position
          const colLetter = cellData.p.match(/[A-Z]+/)[0];
          const rowNumber = parseInt(cellData.p.match(/\d+/)[0], 10);

          // Add the column letter to the set to determine existing columns
          columnSet.add(colLetter);

          // Ensure data has enough rows
          while (data.length < rowNumber) {
            data.push({});
          }

          // Populate cell values into the data array at the correct row position
          if (cellData.f) {
            const result = calculateExcelFormula({ sheetName: selectedSheet, cell: cellData, rawData });
            if (isNaN(result) || result?.[0] === "0") {
              data[rowNumber - 1][colLetter] = result;
            } else {
              data[rowNumber - 1][colLetter] = new Intl.NumberFormat("en-US").format(Number(result));
            }
          } else {
            if (isNaN(cellData?.v) || cellData?.v?.[0] === "0") {
              data[rowNumber - 1][colLetter] = cellData.v;
            } else {
              data[rowNumber - 1][colLetter] = new Intl.NumberFormat("en-US").format(Number(cellData.v));
            }
          }
        }
      }
    }

    // Sort columns alphabetically
    const sortedColumns = Array.from(columnSet).sort();

    // Create column definitions in the correct order for Handsontable
    const original_columns = sortedColumns.map(col => ({ data: col, title: col })) || [];
    const letter = original_columns?.at(-1)?.data;
    let columns = []
    if (letter) {
      columns = this.generateColumns(letter || "A");
    }

    return { data, columns };
  };

  // Fungsi untuk mengambil data dan gaya dari ExcelJS
  getExcelDataWithStyles = async (buffer) => {
    const { feature_key, field_key, geo_layer_id } = this.props;
    const rawWorkbook = XLSX.read(buffer, { type: 'array' });
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(buffer);

    const sheetObjects = [];
    const sheetnames = [];

    workbook.eachSheet((sheet, idx) => {
      const rawSheet = rawWorkbook.Sheets[sheet.name];
      sheetnames.push(sheet.name);

      const sheetData = {
        feature_key,
        field_key,
        geo_layer_id,
        metadata: {
          name: sheet.name,
          id_ori: sheet.name,
          order: idx - 1,
        },
        value: [],
      };

      sheet.eachRow((row) => {
        row.eachCell((cell) => {
          const position = cell.address;
          const rawCell = rawSheet[position] || {};
          const style = cell.style || {};

          // Extract fill color
          let fillColor = null;
          const fgColor = style.fill?.fgColor;
          if (fgColor?.theme !== undefined) {
            fillColor = applyTint(fgColor.theme, fgColor.tint);
          } else if (fgColor?.argb) {
            fillColor = `#${fgColor.argb.slice(fgColor.argb.length > 6 ? 2 : 0)}`;
          }

          // Decoration object
          const decoration = {
            fontColor: style.font?.color ? applyTint(style.font.color.theme, style.font.color.tint) : "#000",
            fontSize: style.font?.size || null,
            fontName: style.font?.name || null,
            bold: !!style.font?.bold,
            italic: !!style.font?.italic,
            underline: !!style.font?.underline,
            fillColor,
            border: {
              top: style.border?.top?.style || null,
              right: style.border?.right?.style || null,
              bottom: style.border?.bottom?.style || null,
              left: style.border?.left?.style || null,
            },
            decimal: style.numFmt || null
          };

          const body = {
            p: position,
            d: decoration,
          };

          // Add dropdown options if available
          if (cell.dataValidation?.type === "list") {
            const dropdownOptions = cell.dataValidation.formulae?.[0];
            if (dropdownOptions) {
              body.o = dropdownOptions;
            }
          }

          // Add raw cell properties
          const rawKeys = ["v", "w", "t", "f", "F", "D", "r", "h", "c", "z", "l", "s"];
          rawKeys.forEach((key) => {
            if (rawCell[key] !== undefined) {
              body[key] = rawCell[key];
            }
          });

          sheetData.value.push(body);
        });
      });

      sheetObjects.push(sheetData);
    });

    // Simpan sheetObjects ke database
    await Promise.all(
      sheetObjects.map(async (sheetData) => {
        const _id = await this.props.create_sheet(sheetData);
        sheetData._id = _id;
      })
    );

    return { sheetObjects, sheetnames };
  };


  define_need_to_show = async (e) => {
    const { feature_key, field_key, geo_layer_id } = this.props;
    const file = get_file(e);
    const buffer = await file.arrayBuffer();

    // const { sheetObjects, sheetnames } = await this.getExcelDataWithStyles(buffer);
    await this.getExcelDataWithStyles(buffer);

    const body = {
      geo_layer_id,
      feature_key,
      field_key
    }

    const data = await this.props.get_sheets_by_cell(body);
    const sheetnames = data?.map(item => ({ _id: item?._id, ...item?.metadata })).sort(item => item?.order);
    this.setState({
      worksheet: data?.length > 0 ? data : null,
      sheetnames: sheetnames,
      selectedSheet: sheetnames?.[0]?.id_ori || "",
      filename: file.name
    })

    // this.setState({
    //   worksheet: sheetObjects,
    //   sheetnames: sheetObjects?.map(item => ({ _id: item?._id, ...item?.metadata })).sort(item => item?.order),
    //   selectedSheet: sheetnames?.[0] || "",
    //   filename: file.name
    // })


    // return null;
  };

  setSelectedSheet = (value) => {
    this.setState({
      selectedSheet: value
    })
  }

  handleSheetChange = (value) => {
    this.setState({
      selectedSheet: value
    })
  }

  setSheetnames = (values) => {
    this.setState({
      sheetnames: values
    })
  }

  setWorksheet = (value) => {
    this.setState({
      worksheet: value
    })
  }

  // componentDidUpdate = async (_, prevState) => {
  //   if (this.state?.worksheet) {
  //     if (!this.state?.selectedSheet && (prevState?.sheetnames?.length !== this.state?.sheetnames?.length)) {
  //       this.setState({ selectedSheet: this.state?.sheetnames?.[0]?.id_ori || "" })
  //     }
  //   }
  // }

  on_dowload_json = () => {
    const { worksheet, filename } = this.state;
    const json_text = JSON.stringify(worksheet);
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(json_text)
    );
    element.setAttribute("download", `${filename?.replaceAll(".xlsx")}.json`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  handle_selected_cell = ({ startRow, startCol, endRow, endCol }) => {
    const { worksheet, selectedSheet } = this.state
    if (startRow === endRow && startCol === endCol) {
      const row = startRow;
      const col = startCol;
      const column = this.hotTableRef?.current?.hotInstance?.getColHeader(col);
      const cellRef = `${column}${row + 1}`
      const sheet = worksheet?.find(item => item?.metadata?.id_ori === selectedSheet);

      if (sheet) {
        const cell = sheet?.value?.find(item => item.p === cellRef);
        this.setState({
          selectedCell: cell,
          isSingleSelected: true,
        })
      }
    } else {
      const startColumn = this.hotTableRef?.current?.hotInstance?.getColHeader(startCol);
      const startCellRef = `${startColumn}${startRow + 1}`
      const endColumn = this.hotTableRef?.current?.hotInstance?.getColHeader(endCol);
      const endCellRef = `${endColumn}${endRow + 1}`
      const sheet = worksheet?.find(item => item?.metadata?.id_ori === selectedSheet);

      if (sheet) {
        const startCell = sheet?.value?.find(item => item.p === startCellRef);
        const endCell = sheet?.value?.find(item => item.p === endCellRef);

        this.setState({
          selectedCells: {
            startCell,
            endCell
          },
          isSingleSelected: false,
        })
      }
    }
  }

  setStyle = (row, col, data, sheet) => {
    const { worksheet, selectedSheet } = this.state
    let cellProperties = {};
    const cellRef = Handsontable.helper.spreadsheetColumnLabel(col)
    let cellData = data?.[row]?.[cellRef]
    const cellFromXlsx = sheet?.value?.find((item) => item?.p === `${cellRef}${row + 1}`);
    const style = cellFromXlsx?.d
    // console.log(cellData, style)

    // if(cellRef === "K"){
    //   console.log(cellFromXlsx)
    // }

    if (cellData && style) {
      if (cellFromXlsx?.o) {
        const { sheetName, cellRef } = parseReference(cellFromXlsx?.o?.replaceAll("$", ""))
        const options = getAllSelectedValue({ rangeCode: cellRef, sheetName: sheetName || selectedSheet, rawData: worksheet })
        cellProperties.type = 'dropdown';
        cellProperties.source = options;
      }

      if (style?.decimal && is_not_empty(cellData) && !isNaN(cellData)) {
        const decimalMatch = style?.decimal.match(/0\.0+|#\.#+/);
        let decimals = 0;

        if (decimalMatch) {
          decimals = (decimalMatch[0].split('.')[1] || '').length;
        }

        // Format value sesuai jumlah desimal di numFmt
        cellData = parseFloat(cellData).toFixed(decimals);
      }

      // Tambahkan gaya
      cellProperties.renderer = function (instance, td) {
        td.innerText = cellData; // Isi nilai sel
        td.style.fontSize = style.fontSize ? `${style.fontSize}px` : '';
        td.style.fontFamily = style.fontName || '';
        td.style.fontWeight = style.bold ? 'bold' : '';
        td.style.fontStyle = style.italic ? 'italic' : '';
        td.style.textDecoration = style.underline ? 'underline' : ''

        if (style?.fillColor?.[0] === "#") {
          td.style.color = style.fontColor
        }

        if (style?.fillColor?.[0] === "#") {
          td.style.backgroundColor = style?.fillColor
        }

        if (style.border) {
          td.style.borderTop = style.border.top ? `${style.border.top.width || 1}px ${style.border.top.style || 'solid'} ${style.border.top.color || '#000'}` : '';
          td.style.borderRight = style.border.right ? `${style.border.right.width || 1}px ${style.border.right.style || 'solid'} ${style.border.right.color || '#000'}` : '';
          td.style.borderBottom = style.border.bottom ? `${style.border.bottom.width || 1}px ${style.border.bottom.style || 'solid'} ${style.border.bottom.color || '#000'}` : '';
          td.style.borderLeft = style.border.left ? `${style.border.left.width || 1}px ${style.border.left.style || 'solid'} ${style.border.left.color || '#000'}` : '';
        }

      };
    }

    return cellProperties;
  }



  render() {
    const {
      uploaded_file,
      modal_upload,
      worksheet,
      selectedSheet,
      sheetnames,
      selectedCell
    } = this.state;
    const language = localStorage?.language ? localStorage?.language : "ina";
    const sheet = worksheet?.find(obj => obj.metadata.id_ori === selectedSheet) || {};
    const { data, columns } = this.convertExcelDataToHandsontableFormat({ sheet, selectedSheet, rawData: worksheet })

    const modal_excel_content = modal_upload && (
      <Modal
        title="Upload Excel"
        modalSize="large"
        id="modal_upload_excel"
        isOpen={modal_upload}
        onClose={this.toggle_modal}
      >
        <div className="box-body" style={{ height: worksheet ? "80vh" : "500px" }}>
          {!worksheet &&
            <div>
              <label>Upload your Sheet (.xlsx)</label>
              <div className='border_dash'>
                <FileUploader
                  classes="container_upload absolute h_full pointer"
                  handleChange={(e) => {
                    this.define_need_to_show(e);
                  }}
                  name="file"
                  types={["xlsx"]}
                  onTypeError={(e) => {
                    this.props.snackbar({
                      is_open: true,
                      status: "error",
                      message: e
                    });
                  }}
                  children={
                    <div className="container_upload center_perfect">
                      <div>
                        {uploaded_file?.fileName ||
                          `${dict["Drop a file here"][language]}`}
                      </div>
                    </div>
                  }
                />
              </div>


            </div>
          }

          {/* {
            worksheet && <Button onClick={() => this.on_dowload_json()}>
              Download
            </Button>
          } */}

          {/* <select id="sheetSelector" value={selectedSheet} onChange={(e) => this.handleSheetChange(e)}>
            {worksheet && sheetnames?.map(sheet => (
              <option key={sheet} value={sheet}>{sheet}</option>
            ))}
          </select> */}
          {/* <input
            value={ }
            disabled
          /> */}

          {worksheet && <section>
            <input
              type="text"
              value={selectedCell?.f ? `=${selectedCell?.f}` : (selectedCell?.v || "")}
              disabled
              style={{
                width: '100%',
                marginBottom: '10px',
                padding: '8px',
                fontSize: '16px'
              }}
            />

            <HotTable
              style={{
                border: "1px solid #AAA",
                borderRadius: "4px"
              }}
              cells={(row, col) => this.setStyle(row, col, data, sheet)}
              ref={this.hotTableRef}
              width="100%"
              data={data}
              columns={columns}
              header
              rowHeaders={true}
              colHeaders={true}
              height={(65 / 100) * window.innerHeight}
              licenseKey="non-commercial-and-evaluation" // for non-commercial use only
              settings={{
                minSpareRows: 5,
                minSpareCols: 5, // Tambahkan baris kosong di akhir
                contextMenu: true, // Menampilkan opsi konteks (cut, copy, paste)
                manualColumnResize: true,
                manualRowResize: true,
              }}
              selectionMode='multiple'
              afterSelectionEnd={(startRow, startCol, endRow, endCol) => {
                this.handle_selected_cell({ startRow, startCol, endRow, endCol })
              }}
              // afterSelection={(startRow, startCol, endRow, endCol) => {
              //   this.handle_selected_cell({ startRow, startCol, endRow, endCol })
              // }}
              modifyColWidth={(width, col) => {
                if (width > 300) {
                  return 300
                }
              }}
            />

            {worksheet &&
              <div className='w_full scroll_x_auto'>
                <div className='w_full flex_row flex align_center no_wrap gap_10 h_50'>
                  <label>Sheet:</label>
                  {sheetnames?.map(item => (
                    <SheetButton
                      key={item?.id_ori}
                      handleSheetChange={this.handleSheetChange}
                      sheetname={item}
                      selectedSheet={selectedSheet}
                      setSheetnames={this.setSheetnames}
                      sheetnames={sheetnames}
                      setSelectedSheet={this.setSelectedSheet}
                      worksheet={worksheet}
                      setWorksheet={this.setWorksheet}
                    />
                  ))}
                </div>
              </div>
            }
          </section>}

        </div>
      </Modal>
    );

    return (
      <div>

        <button
          onClick={this.toggle_modal}
          style={{
            color: "#0166cb",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            textTransform: "uppercase",
          }}
        >
          {"Excel"}
        </button>
        {modal_excel_content}
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  geo_layer_id: getGeoLayer(state.layer)._id,
});

export default connect(mapStateToProps, {
  get_sheets_by_cell,
  create_sheet,
  snackbar
})(ExcelTable);