/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  set_value_insight,
  insight_update,
} from "../../App/actions/insight_v2";

/*PICTURE ASSET*/

/*FUNCTION*/

/*DATA*/
import manifest_demography from "../../Data/manifest_demography.json";

/*NON IMPORT*/

class INPUT_DEMOGRAPHY extends Component {
  state = {};

  toggle_config_demography = (item) => {
    let { array_formula_insight } = this.props.insight_reducer;

    if (!array_formula_insight.some((element) => element.key === item)) {
      //belum ada, set
      array_formula_insight.push({
        key: item,
        is_directly_proportional: true,
        min: 0,
        max: 0,
        weight_score: 1,
        group: "demography",
      });
    } else {
      //sudah ada, delete
      array_formula_insight = array_formula_insight.filter(
        (element) => element.key !== item
      );
    }
    this.props.set_value_insight({
      key: "array_formula_insight",
      value: array_formula_insight,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";
    const manifest_demography_new = manifest_demography.filter(
      (item) =>
        //hanya ambil kolom demografi yang angka dan relevan
        (item.chart_type !== "line" && item.chart_type !== "Table") ||
        item.key === "PENDUDUK"
    );

    //local state

    //global props
    const { array_formula_insight } = this.props.insight_reducer;

    return (
      <main>
        <section className="parent_tile">
          {manifest_demography_new.map((item, idx) => {
            return (
              <div
                key={idx}
                className="container_light child_tile outline_transparent background_grey_light"
              >
                <h1 className="text_bold">{item[language]}</h1>
                <hr />
                <table>
                  <tbody>
                    {item.child.map((child, idx_child) => {
                      const is_active = array_formula_insight.some(
                        (element) => element.key === child.key
                      );
                      return (
                        <tr key={idx_child}>
                          <td>
                            <div
                              className={
                                is_active ? "checklist_active" : "checklist_not"
                              }
                              onClick={this.toggle_config_demography.bind(
                                this,
                                child.key
                              )}
                            />
                          </td>
                          <td>{child[language]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            );
          })}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  insight_reducer: state.insight_reducer,
});

export default connect(mapStateToProps, { set_value_insight, insight_update })(
  INPUT_DEMOGRAPHY
);
